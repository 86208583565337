import clsx from 'clsx';
import { BaseColor } from '../../../types';
import { DIVIDER_COLOR_TO_CLASSNAME } from './Divider.constants';
import './Divider.css';
import { DividerProps, DividerType } from './Divider.types';

/** Divider component for rendering a horizontal or vertical divider with optional text. */
const Divider = ({ color = BaseColor.BASE_300, className, text, type = DividerType.HORIZONTAL }: DividerProps) => {
  const isHorizontal = type === DividerType.HORIZONTAL;
  return (
    <div
      className={clsx(
        'divider m-0',
        isHorizontal ? 'divider-vertical h-fit' : 'divider-horizontal w-fit',
        DIVIDER_COLOR_TO_CLASSNAME[color],
        className
      )}
    >
      {text}
    </div>
  );
};

export default Divider;
