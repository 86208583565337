import { useRef, useState } from 'react';
import { ButtonColor, ButtonVariant, Divider, DividerType, Icon, TextButton, TextInput } from '../../../../shared';
import ScorecardSection from './ScorecardSection';
import { Section } from '../../../../../types';
import { v4 as uuidv4 } from 'uuid';
import { Scorecard } from '../../../../../types';
import ScorecardInUseModal from './ScorecardInUseModal';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { INITIAL_SECTIONS } from '../../../../../constants';

interface ScorecardFormProps {
  onBack: () => void;
  onSave: (scorecard: Scorecard) => void;
  onDelete: (scorecardId: string) => void;
  onDuplicate: (scorecard: Scorecard) => void;
  scorecard?: Scorecard;
}

// TODO: Apply dynamic height calculation in scorecards and candidates tables
const SCORECARD_FORM_HEIGHT = 350;

const ScorecardForm = ({ onBack, onSave, onDelete, scorecard, onDuplicate }: ScorecardFormProps) => {
  const [scorecardName, setScorecardName] = useState(scorecard?.name || '');
  const [error, setError] = useState('');

  const [sections, setSections] = useState<Section[]>(scorecard?.sections || INITIAL_SECTIONS);

  const [isInUseModalOpen, setIsInUseModalOpen] = useState(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Add a new section to scorecard
  const handleAddSection = () => {
    // ID is a unique identifier for the section to handle reordering in frontend before saving
    setSections([...sections, { name: '', questions: [], id: uuidv4() }]);

    // Scroll to the added section
    setTimeout(() => {
      scrollContainerRef.current?.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  // Update a section in scorecard
  const handleUpdateSection = (index: number, updatedSection: Section) => {
    const newSections = [...sections];
    newSections[index] = updatedSection;
    setSections(newSections);
  };

  // Remove a section from scorecard
  const handleRemoveSection = (index: number) => setSections(sections.filter((_, i) => i !== index));

  // Save the scorecard data to the backend
  const handleSaveScorecard = () => {
    // If the scorecard is in use, open the modal to create a copy of the scorecard
    if (scorecard?.isUsed) {
      setIsInUseModalOpen(true);
    } else {
      saveScorecard();
    }
  };

  const saveScorecard = (name: string = scorecardName) => {
    const updatedScorecard: Scorecard = {
      id: scorecard?.id || '', // If editing, use existing id; otherwise, it will be generated in the parent component
      name: name,
      sections: sections,
      creator: scorecard?.creator || '',
      creatorAvatarUrl: scorecard?.creatorAvatarUrl || '',
      updatedOn: new Date(),
      isUsed: false, // Reset isUsed flag for the new scorecard
    };

    onSave(updatedScorecard);
  };

  const handleConfirmNewScorecard = (newName: string) => {
    if (!scorecard) return;

    const newScorecard: Scorecard = {
      ...scorecard,
      name: newName,
      sections: sections,
    };
    onDuplicate(newScorecard);
    setIsInUseModalOpen(false);
  };

  // Delete the scorecard
  const handleDeleteScorecard = () => {
    if (!scorecard || !scorecard.id) {
      onBack();
      return;
    }

    if (scorecard.isUsed) {
      // Archive the scorecard
      const archivedScorecard: Scorecard = {
        ...scorecard,
        isArchived: true,
        updatedOn: new Date(),
      };
      onSave(archivedScorecard);
    } else {
      // Delete the scorecard
      onDelete(scorecard.id);
    }
  };

  // Handle scorecard name change
  const handleScorecardNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScorecardName(e.target.value);
    setError('');
  };

  // Validate the scorecard data before saving
  // Scorecard name, at least 1 valid section and at least 1 question within each section are required
  const validateSaveScorecard = () => {
    return (
      scorecardName.trim() !== '' &&
      sections.length > 0 &&
      sections.every(
        (section) =>
          section.name.trim() !== '' &&
          section.questions.length > 0 &&
          section.questions.every((question) => !!question.text && !!question.type)
      )
    );
  };

  // Handle section drag end event
  const handleSectionDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedSections = Array.from(sections);
    const [movedSection] = reorderedSections.splice(result.source.index, 1);
    reorderedSections.splice(result.destination.index, 0, movedSection);

    setSections(reorderedSections);
  };

  return (
    <DragDropContext onDragEnd={handleSectionDragEnd}>
      <div className="flex h-full flex-col justify-between">
        <div
          ref={scrollContainerRef}
          className="display-scrollbar-lg flex h-full flex-col gap-4 overflow-auto pb-4"
          style={{ maxHeight: SCORECARD_FORM_HEIGHT }}
        >
          <div className="flex flex-col gap-2">
            <div className="flex items-start gap-4">
              <TextButton text="Back" startIcon={Icon.CHEVRON_LEFT} onClick={onBack} color={ButtonColor.SECONDARY} />
              <TextInput
                placeholder="Scorecard name*"
                onChange={handleScorecardNameChange}
                value={scorecardName}
                error={error}
              />
            </div>
            <TextButton
              text="Add section"
              startIcon={Icon.PLUS}
              onClick={handleAddSection}
              variant={ButtonVariant.OUTLINE}
            />
          </div>
          <Droppable
            droppableId="sections"
            renderClone={(provided, _snapshot, rubric) => (
              <ScorecardSection
                section={sections[rubric.source.index]}
                dragHandleProps={provided.dragHandleProps || undefined}
                draggableProps={provided.draggableProps || undefined}
                innerRef={provided.innerRef}
              />
            )}
          >
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
                className="flex flex-col gap-4"
              >
                {sections.map((section, index) => (
                  <Draggable draggableId={section.id} index={index} key={section.id}>
                    {(draggableProvided) => (
                      <ScorecardSection
                        section={section}
                        onUpdate={(updatedSection) => handleUpdateSection(index, updatedSection)}
                        onRemove={() => handleRemoveSection(index)}
                        dragHandleProps={draggableProvided.dragHandleProps || undefined}
                        draggableProps={draggableProvided.draggableProps || undefined}
                        innerRef={draggableProvided.innerRef}
                      />
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <div className="flex flex-col gap-4">
          <Divider type={DividerType.HORIZONTAL} />
          <div className="flex items-end justify-end gap-2 pb-6">
            <TextButton
              text={scorecard?.isUsed ? 'Archive' : 'Delete'}
              onClick={handleDeleteScorecard}
              variant={ButtonVariant.OUTLINE}
              color={ButtonColor.DESTRUCTIVE}
            />
            <TextButton
              text={scorecard?.isUsed ? 'Save as' : 'Save'}
              onClick={handleSaveScorecard}
              disabled={!validateSaveScorecard()}
            />
          </div>
        </div>
        <ScorecardInUseModal
          isOpen={isInUseModalOpen}
          setIsOpen={setIsInUseModalOpen}
          initialScorecardName={`Copy of ${scorecardName}`}
          onConfirm={handleConfirmNewScorecard}
        />
      </div>
    </DragDropContext>
  );
};

export default ScorecardForm;
