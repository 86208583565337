import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { ComponentSize, TagColor } from '../../../types';
import { conditionalObject, convertToPx } from '../../../utils';
import { BadgeDot } from '../BadgeDot';
import { Icons } from '../Icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip';
import { BADGE_ICON_SIZE, BADGE_SIZE, FILLED_BADGE_ICON_COLOR, OUTLINE_BADGE_ICON_COLOR } from './Badge.constants';
import { BadgeColor, BadgeProps } from './Badge.types';

const Badge = ({
  label,
  color = BadgeColor.DEFAULT,
  dotColor = TagColor.BLUE,
  outline = false,
  showDot,
  icon,
  maxWidth,
  size = ComponentSize.SMALL,
  onClick,
}: BadgeProps) => {
  const labelRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const handleClick = onClick
    ? (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }
    : undefined;

  useEffect(() => {
    if (labelRef.current) {
      setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
    }
  }, [label, maxWidth]);

  return (
    <Tooltip>
      <TooltipTrigger>
        <div
          className={clsx(
            'badge flex items-center justify-center gap-2',
            outline && 'badge-outline',
            onClick && 'cursor-pointer hover:opacity-50',
            color,
            BADGE_SIZE[size]
          )}
          style={{ maxWidth: convertToPx(maxWidth) }}
          onClick={handleClick}
        >
          {showDot && <BadgeDot color={dotColor} />}
          {icon && (
            <Icons
              icon={icon}
              size={BADGE_ICON_SIZE[size]}
              color={outline ? OUTLINE_BADGE_ICON_COLOR[color] : FILLED_BADGE_ICON_COLOR[color]}
            />
          )}
          <div
            ref={labelRef}
            className={clsx('whitespace-nowrap', !outline && color === BadgeColor.NEUTRAL && 'text-white')}
            style={{
              ...conditionalObject(!!maxWidth, {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }),
            }}
          >
            {label}
          </div>
        </div>
      </TooltipTrigger>
      {/* Show tooltip only when the label is overflowing */}
      {isOverflowing && <TooltipContent>{label}</TooltipContent>}
    </Tooltip>
  );
};

export default Badge;
