import { MAX_ROWS, NOTES_HEIGHT, TAG_GAP, TAG_HEIGHT } from './TagsAndNotesCell.constants';

export const getMaxTagsHeight = () => {
  const maxTagsHeight = TAG_HEIGHT * MAX_ROWS + TAG_GAP * (MAX_ROWS - 1);
  return maxTagsHeight;
};

export const getMaxTagsAndNotesHeight = () => {
  const maxTagsAndNotesHeight = getMaxTagsHeight() + NOTES_HEIGHT * MAX_ROWS + TAG_GAP * (MAX_ROWS - 1);
  return maxTagsAndNotesHeight;
};
