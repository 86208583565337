import { useEffect, useState } from 'react';
import { BaseColor, MousePosition } from '../../../types';
import { DataTable } from '../DataTable';
import { Divider, DividerType } from '../Divider';
import { Dropdown, DropdownContent } from '../Dropdown';
import DataTableActionButton from './DataTableActionButton';
import { DataTableWithActionsProps } from './DataTableWithActions.types';

/** The DataTableWithActions component displays a data table with actionable items for each row. */
const DataTableWithActions = <T extends object>({
  actions = [],
  isLoading,
  setClickedRowIndex,
  clickedRowIndex,
  ...dataTableProps
}: DataTableWithActionsProps<T>) => {
  // State to track the position of the click event.
  const [clickPosition, setClickPosition] = useState<MousePosition | undefined>(undefined);

  const isActionsPopoverOpen = clickedRowIndex !== undefined;
  const closeActionsPopover = () => setClickedRowIndex(undefined);

  useEffect(() => {
    if (isLoading && isActionsPopoverOpen) {
      closeActionsPopover();
    }
  }, [isLoading]);

  // Handle row click event to set the click position and row index.
  const handleRowClick = (e: React.MouseEvent, index: number) => {
    setClickPosition({ x: e.clientX, y: e.clientY });
    setClickedRowIndex(index === clickedRowIndex ? undefined : index);
  };

  return (
    <>
      <DataTable
        isLoading={isLoading}
        onRowClick={actions.length ? handleRowClick : undefined}
        activeRowIndex={clickedRowIndex}
        {...dataTableProps}
      />
      {!!actions.length && (
        <Dropdown position={clickPosition} open={isActionsPopoverOpen} onOpenChange={closeActionsPopover}>
          <DropdownContent className="flex flex-row">
            {actions.map(({ icon, label, onClick }, index) => (
              <div key={label} className="flex items-center">
                <DataTableActionButton icon={icon} label={label} onClick={onClick} />
                {index < actions.length - 1 && (
                  <Divider type={DividerType.VERTICAL} className="mx-1" color={BaseColor.BASE_200} />
                )}
              </div>
            ))}
          </DropdownContent>
        </Dropdown>
      )}
    </>
  );
};

export default DataTableWithActions;
