import { ButtonColor, ButtonVariant, TextButton } from '../../shared';

interface CreateProspectSubmitItemProps {
  children: React.ReactNode;
  disabled: boolean;
  isLoading: boolean;
  onSubmit: () => void;
}

const CreateProspectSubmitItem = ({ children, disabled, isLoading, onSubmit }: CreateProspectSubmitItemProps) => {
  return (
    <div className="mt-4 flex w-[90%] gap-2">
      {children}
      <TextButton
        text="Submit"
        onClick={onSubmit}
        color={ButtonColor.SECONDARY}
        disabled={disabled}
        variant={ButtonVariant.OUTLINE}
        loading={isLoading}
        className="mb-4"
      />
    </div>
  );
};

export default CreateProspectSubmitItem;
