import { useEffect, useState } from 'react';

const useCheckMicPermissions = () => {
  const [micPermissionState, setMicPermissionState] = useState<PermissionState | null>(null);

  useEffect(() => {
    // Check if mic permission is already enabled
    const checkMicPermissions = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName });
        setMicPermissionState(permissionStatus.state);

        // Listen for changes in permission state
        permissionStatus.onchange = () => {
          setMicPermissionState(permissionStatus.state);
        };
      } catch (error) {
        console.error('Error checking microphone permissions:', error);
        setMicPermissionState(null);
      }
    };

    checkMicPermissions();
  }, []);

  return micPermissionState;
};

export default useCheckMicPermissions;
