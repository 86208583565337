import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AMELIA_RESEARCHER } from '../../constants/prospect.constants';
import { PracticeProspect } from '../../types';

const getNextProspect = (prospects: PracticeProspect[], currProspect: PracticeProspect) => {
  const currProspectIndex = prospects.findIndex((prospect) => prospect.personaId === currProspect.personaId);
  if (currProspectIndex === -1) {
    return undefined;
  }

  const isLastProspect = currProspectIndex === prospects.length - 1;
  let nextProspect = prospects.length ? prospects[isLastProspect ? 0 : currProspectIndex + 1] : undefined;

  // If the next prospect is the same as the current prospect, set nextProspect to undefined.
  if (nextProspect && nextProspect.personaId === currProspect.personaId) {
    nextProspect = undefined;
  }

  return nextProspect;
};

export type WebCallData = {
  currProspect: PracticeProspect;
  nextProspect?: PracticeProspect;
};

/**
 * Interface for the web call state.
 */
interface WebCallState {
  /**
   * List of prospects in the order they can be called.
   * After ending a call, the user can call the next prospect in this sequence.
   */
  prospects: PracticeProspect[];
  /**
   * Information about the current web call.
   * Undefined when no call is in progress.
   */
  currWebCall?: WebCallData;
}

/**
 * Initial state for the web call.
 */
const INITIAL_WEB_CALL_STATE: WebCallState = {
  prospects: [],
};

// Create a slice for managing the web call state.
const webCallReducer = createSlice({
  name: 'webCall',
  initialState: INITIAL_WEB_CALL_STATE,
  reducers: {
    // Set the list of prospects for the web call.
    setWebCallProspectsList: (state, action: PayloadAction<PracticeProspect[]>) => {
      state.prospects = action.payload;
    },
    // Start a web call with the given prospect by setting the currWebCall state
    // which triggers opening the WebCallModal and starting the call.
    startWebCall: (state, action: PayloadAction<PracticeProspect>) => {
      const currProspect = action.payload;
      const nextProspect = getNextProspect(state.prospects, currProspect);

      state.currWebCall = {
        currProspect,
        nextProspect,
      };
    },
    // Start a web call with Amelia Researcher.
    startWebCallWithResearcher: (state) => {
      state.currWebCall = {
        currProspect: AMELIA_RESEARCHER,
      };
    },
    // Start the next web call by setting the currWebCall state with the next prospect.
    startNextWebCall: (state) => {
      if (!state.currWebCall || !state.currWebCall.nextProspect) return;

      const currProspect = state.currWebCall.nextProspect;
      const nextProspect = getNextProspect(state.prospects, currProspect);

      state.currWebCall = {
        currProspect,
        nextProspect,
      };
    },
    // Close the web call modal.
    closeWebCall: (state) => {
      state.currWebCall = undefined;
    },
  },
});

export const { setWebCallProspectsList, startWebCall, startWebCallWithResearcher, startNextWebCall, closeWebCall } =
  webCallReducer.actions;
export default webCallReducer.reducer;
