// Query parameter name for specifying the start time of a call in the URL.
export const CALL_START_TIME_QUERY_PARAM = 'startTime';

// Default number of items per page for pagination.
export const PAGINATION_PAGE_SIZE = 25;
export const SINGLE_AUDIO_WAVE_HEIGHT = 30;

// App url
export const APP_URL = 'https://fullyramped.com';

// App privacy policy url
export const PRIVACY_POLICY_URL = `${APP_URL}/resources/privacy-policy`;

export const SIDEBAR_WIDTH = 256;

// Width for table columns.
export const DATE_COLUMN_WIDTH = '120px';

// AWS RUM VERSION
export const RUM_APPLICATION_VERSION = '1.0.0';
