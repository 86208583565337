import clsx from 'clsx';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { AlertType, Icon, Icons, MultiSelect } from '../../components';
import { useToast } from '../../hooks';
import { useApplyTagsMutation, useCreateTagMutation, useGetTagsQuery } from '../../services';
import { ComponentSize, DropdownPlacement, Tag, TagColor, TextColor } from '../../types';

const CREATE_TAG_OPTION = 'CREATE_TAG_OPTION';
const APPLY_TAGS_ERROR_MSG = 'Failed to edit tags';

interface ManageTagsDropdownProps {
  isOpen: boolean;
  prospectId: string;
  prospectTags: Tag[];
  selectedTags: Tag[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedTags: (tags: Tag[]) => void;
}

const ManageTagsDropdown = ({
  isOpen,
  prospectId,
  prospectTags,
  selectedTags,
  setIsOpen,
  setSelectedTags,
}: ManageTagsDropdownProps) => {
  const [searchValue, setSearchValue] = useState('');

  const { data: tags = [], isLoading: isTagsLoading } = useGetTagsQuery();
  const [applyTags, { isLoading: isApplyingTags }] = useApplyTagsMutation();
  const [createTag, { isLoading: isCreatingTag }] = useCreateTagMutation();

  const { showToast } = useToast();

  const options = tags.map((tag) => ({ color: tag.color, label: tag.name, value: tag.id }));

  const handleCreateTag = useCallback(async () => {
    if (!searchValue.length) return;

    try {
      const randomColor = Object.values(TagColor)[Math.floor(Math.random() * Object.values(TagColor).length)];
      await createTag({ name: searchValue, color: randomColor });
    } catch (error) {
      showToast({ message: `Failed to create tag "${searchValue}"`, type: AlertType.ERROR });
    }
  }, [searchValue, createTag, showToast]);

  const handleApplyTags = useCallback(async () => {
    try {
      // Check if nothing has changed
      const tagsToApply = selectedTags.filter((newTag) => !prospectTags.find((oldTag) => oldTag.id === newTag.id));
      const tagsToRemove = prospectTags.filter((oldTag) => !selectedTags.find((newTag) => newTag.id === oldTag.id));

      if (tagsToApply.length === 0 && tagsToRemove.length === 0) {
        return; // Nothing has changed, so we return early
      }

      // The tags to apply are the selected tags that do not currently exist in the prospect's tags.
      const tagIdsToApply = tagsToApply.map((tag) => tag.id);

      // The tags to remove are the prospect's tags that are not selected anymore.
      const tagIdsToRemove = tagsToRemove.map((tag) => tag.id);

      await applyTags({ prospectId, tagsToApply: tagIdsToApply, tagsToRemove: tagIdsToRemove });
    } catch (error) {
      console.error(`${APPLY_TAGS_ERROR_MSG}: `, error);
      showToast({ message: APPLY_TAGS_ERROR_MSG, type: AlertType.ERROR });
      // Reset the selected tags to the prospect's tags on error.
      setSelectedTags(prospectTags);
    }
  }, [prospectId, selectedTags, prospectTags, applyTags, showToast]);

  useEffect(() => {
    setSelectedTags(prospectTags);
  }, [prospectTags]);

  return (
    <MultiSelect
      controlledOpenProps={{ isOpen, setIsOpen }}
      options={options}
      selected={selectedTags.map((tag) => ({ label: tag.name, value: tag.id }))}
      onChange={(newValues?: string[]) => {
        const newTags = tags.filter((tag) => newValues?.includes(tag.id));
        setSelectedTags(newTags);
      }}
      placement={DropdownPlacement.BOTTOM_START}
      disabled={isTagsLoading}
      searchProps={{
        searchValue,
        setSearchValue,
        placeholder: 'Type to filter or create',
        disabled: isApplyingTags || isCreatingTag,
        createOption: {
          icon: Icon.PLUS,
          label: `Create new tag "${searchValue}"`,
          value: CREATE_TAG_OPTION,
          onClick: handleCreateTag,
        },
      }}
      customButton={
        <button
          className={clsx(
            'h-3 w-3 cursor-pointer rounded-full',
            isOpen ? 'bg-gray-500' : 'bg-gray-300 hover:bg-gray-100'
          )}
        >
          <Icons icon={Icon.PLUS} size={ComponentSize.X_SMALL} color={TextColor.SECONDARY} />
        </button>
      }
      onClose={handleApplyTags}
    />
  );
};

export default ManageTagsDropdown;
