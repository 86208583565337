import React, { useCallback, useRef, useState } from 'react';
import { Badge, BadgeColor, Icon, Typography, TypographySize } from '../..';
import { useDetectTextOverflow, useObserveElementResize } from '../../../hooks';
import { DropdownPlacement, Tag, TextColor } from '../../../types';
import { MAX_ROWS, MAX_TAG_WIDTH, TAG_GAP } from './TagsAndNotesCell.constants';
import { getMaxTagsHeight } from './TagsAndNotesCell.utils';

interface TagsAndNotesCellProps {
  selectedTags: Tag[];
  notes?: string;
  onAddTagsClick?: () => void;
}

const TagsAndNotesCell: React.FC<TagsAndNotesCellProps> = ({ selectedTags, notes, onAddTagsClick }) => {
  const [visibleTags, setVisibleTags] = useState<Tag[]>([]);
  const [hiddenTags, setHiddenTags] = useState<Tag[]>([]);

  const badgeContainerRef = useRef<HTMLDivElement>(null);
  const notesRef = useRef<HTMLDivElement>(null);

  const isNotesOverflowing = useDetectTextOverflow(notesRef, MAX_ROWS);

  const maxTagsHeight = getMaxTagsHeight();

  const checkTagsOverflow = useCallback(() => {
    const badgeContainerElement = badgeContainerRef.current;
    if (!badgeContainerElement || !selectedTags.length) return;

    // Clone the badge container to measure overflow.
    const tempElement = badgeContainerElement.cloneNode(true) as HTMLDivElement;
    tempElement.style.position = 'absolute';
    tempElement.style.visibility = 'hidden';
    tempElement.style.width = `${badgeContainerElement.offsetWidth}px`;

    // Append the cloned element to the body.
    document.body.appendChild(tempElement);

    let visibleCount = 0;

    // Determine how many tags fit within the max height.
    for (let i = 0; i < tempElement.children.length; i++) {
      const child = tempElement.children[i] as HTMLElement;
      const childTop = child.offsetTop;

      if (childTop + child.offsetHeight + TAG_GAP <= badgeContainerElement.offsetTop + maxTagsHeight) {
        visibleCount++;
      } else {
        break;
      }
    }

    setVisibleTags(selectedTags.slice(0, visibleCount));
    setHiddenTags(selectedTags.slice(visibleCount));

    // Cleanup: remove the temporary element from the body.
    document.body.removeChild(tempElement);
  }, [selectedTags]);

  // Observe changes in the badge container size.
  useObserveElementResize(badgeContainerRef, checkTagsOverflow);

  // Render the "+X more" label for hidden tags.
  const renderMoreLabel = (lastVisibleTag: Tag) => (
    <div className="absolute left-0 top-0 flex h-full w-full items-center">
      <Typography
        size={TypographySize.CAPTION}
        color={TextColor.SECONDARY}
        noWrap
        tooltip={{
          content: (
            <div className="flex flex-col">
              <span>• {lastVisibleTag.name}</span>
              {hiddenTags.map((tag) => (
                <span key={tag.id}>• {tag.name}</span>
              ))}
            </div>
          ),
          placement: DropdownPlacement.BOTTOM,
        }}
      >
        +{hiddenTags.length + 1} more
      </Typography>
    </div>
  );

  return (
    <div className="flex flex-col gap-1">
      {/* Tag container */}
      <div
        className="flex flex-wrap gap-1 overflow-hidden"
        ref={badgeContainerRef}
        style={{ maxHeight: maxTagsHeight }}
      >
        {/* Show "Add tags" badge if no tags are selected. */}
        {onAddTagsClick && !selectedTags.length && (
          <Badge label="Add tags" color={BadgeColor.GHOST} outline icon={Icon.PLUS} onClick={onAddTagsClick} />
        )}
        {!onAddTagsClick && !selectedTags.length && (
          <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
            No tags or notes
          </Typography>
        )}
        {/* Render selected tags. */}
        {selectedTags.map((tag, index) => {
          // If this is the last visible tag, we hide it and display the "+X more" label
          // to avoid any more overflow calculations.
          const isLastVisibleTag = hiddenTags.length > 0 && index === visibleTags.length - 1;
          return (
            <div key={tag.id} className="relative inline-block">
              <div style={{ opacity: isLastVisibleTag ? 0 : 1 }}>
                <Badge
                  label={tag.name}
                  dotColor={tag.color}
                  color={BadgeColor.GHOST}
                  showDot
                  maxWidth={MAX_TAG_WIDTH}
                />
              </div>
              {isLastVisibleTag && renderMoreLabel(tag)}
            </div>
          );
        })}
      </div>
      {/* Notes with overflow detection */}
      <Typography
        size={TypographySize.CAPTION}
        color={TextColor.SECONDARY}
        ref={notesRef}
        maxLines={MAX_ROWS}
        tooltip={
          isNotesOverflowing
            ? {
                content: notes,
                placement: DropdownPlacement.BOTTOM,
              }
            : undefined
        } // Show full text in tooltip on hover
      >
        {notes}
      </Typography>
    </div>
  );
};

export default TagsAndNotesCell;
