import { Question, QuestionType, Scorecard, Section } from '../types';
import { v4 as uuidv4 } from 'uuid';

export const INITIAL_QUESTION: Question = {
  id: uuidv4(),
  text: '',
};

export const INITIAL_SECTION: Section = {
  id: uuidv4(),
  name: '',
  questions: [INITIAL_QUESTION],
};

// Initial sections array with at least one section and at least one question
export const INITIAL_SECTIONS: Section[] = [INITIAL_SECTION];

// TODO: Remove this after backend is ready
export const DUMMY_SCORECARDS: Scorecard[] = [
  {
    id: uuidv4(),
    name: 'Copy of Candidate evals',
    creator: 'Amelia Elverson',
    updatedOn: new Date('2023-09-09T13:09:00'),
    isUsed: true,
    sections: [
      {
        id: uuidv4(),
        name: 'Call Opening',
        questions: [
          { id: uuidv4(), text: 'Introduced self and company clearly', type: QuestionType.RANGE },
          { id: uuidv4(), text: 'Established rapport with prospect', type: QuestionType.YES_NO },
        ],
      },
      {
        id: uuidv4(),
        name: 'Needs Assessment',
        questions: [
          { id: uuidv4(), text: 'Asked relevant probing questions', type: QuestionType.RANGE },
          { id: uuidv4(), text: 'Identified prospects pain points', type: QuestionType.YES_NO },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Aaron’s cold call scorecard',
    creator: 'Amelia Elverson',
    updatedOn: new Date('2023-08-26T17:22:00'),
    isUsed: true,
    sections: [
      {
        id: uuidv4(),
        name: 'Product Knowledge',
        questions: [
          { id: uuidv4(), text: 'Explained features clearly', type: QuestionType.RANGE },
          { id: uuidv4(), text: 'Highlighted relevant benefits', type: QuestionType.YES_NO },
        ],
      },
      {
        id: uuidv4(),
        name: 'Objection Handling',
        questions: [
          { id: uuidv4(), text: 'Addressed concerns effectively', type: QuestionType.RANGE },
          { id: uuidv4(), text: 'Used appropriate rebuttals', type: QuestionType.YES_NO },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Week of 7/25 Scorecard',
    creator: 'Aaron Marks',
    updatedOn: new Date('2023-08-25T14:22:00'),
    sections: [
      {
        id: uuidv4(),
        name: 'Call Structure',
        questions: [
          { id: uuidv4(), text: 'Followed prescribed call flow', type: QuestionType.RANGE },
          { id: uuidv4(), text: 'Managed time effectively', type: QuestionType.RANGE },
          { id: uuidv4(), text: 'Used appropriate transitions', type: QuestionType.YES_NO },
        ],
      },
      {
        id: uuidv4(),
        name: 'Closing Techniques',
        questions: [
          { id: uuidv4(), text: 'Attempted to close appropriately', type: QuestionType.RANGE },
          { id: uuidv4(), text: 'Handled last-minute objections', type: QuestionType.YES_NO },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Discovery scorecard',
    creator: 'Brenden Arias',
    updatedOn: new Date('2023-08-12T13:12:00'),
    sections: [
      {
        id: uuidv4(),
        name: 'Prospect Qualification',
        questions: [
          { id: uuidv4(), text: 'Identified decision-makers', type: QuestionType.YES_NO },
          { id: uuidv4(), text: 'Uncovered budget information', type: QuestionType.RANGE },
          { id: uuidv4(), text: 'Determined timeline for purchase', type: QuestionType.RANGE },
        ],
      },
      {
        id: uuidv4(),
        name: 'Need Exploration',
        questions: [
          { id: uuidv4(), text: 'Uncovered key pain points', type: QuestionType.RANGE },
          { id: uuidv4(), text: 'Explored current solutions', type: QuestionType.YES_NO },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Onboarding n00bs',
    creator: 'Khadija Hammoud',
    updatedOn: new Date('2023-08-10T12:22:00'),
    sections: [
      {
        id: uuidv4(),
        name: 'Call Preparation',
        questions: [
          { id: uuidv4(), text: 'Researched prospect before call', type: QuestionType.YES_NO },
          { id: uuidv4(), text: 'Prepared relevant questions', type: QuestionType.RANGE },
        ],
      },
      {
        id: uuidv4(),
        name: 'Call Execution',
        questions: [
          { id: uuidv4(), text: 'Followed call script effectively', type: QuestionType.RANGE },
          { id: uuidv4(), text: 'Adapted to prospects responses', type: QuestionType.RANGE },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Warmest of warmups',
    creator: 'Aaron Marks',
    updatedOn: new Date('2023-08-08T20:07:00'),
    isArchived: true,
    sections: [
      {
        id: uuidv4(),
        name: 'Rapport Building',
        questions: [
          { id: uuidv4(), text: 'Used prospects name appropriately', type: QuestionType.RANGE },
          { id: uuidv4(), text: 'Demonstrated active listening', type: QuestionType.RANGE },
          { id: uuidv4(), text: 'Showed empathy towards prospect', type: QuestionType.YES_NO },
        ],
      },
      {
        id: uuidv4(),
        name: 'Value Proposition',
        questions: [
          { id: uuidv4(), text: 'Clearly articulated product benefits', type: QuestionType.RANGE },
          { id: uuidv4(), text: 'Tailored pitch to prospects needs', type: QuestionType.YES_NO },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'HOT LEADS 🔥 ',
    creator: 'Rony Labib',
    updatedOn: new Date('2023-08-06T11:11:00'),
    isArchived: true,
    sections: [
      {
        id: uuidv4(),
        name: 'Lead Qualification',
        questions: [
          { id: uuidv4(), text: 'Verified prospects authority', type: QuestionType.RANGE },
          { id: uuidv4(), text: 'Assessed urgency of need', type: QuestionType.YES_NO },
          { id: uuidv4(), text: 'Determined budget range', type: QuestionType.RANGE },
        ],
      },
      {
        id: uuidv4(),
        name: 'Next Steps',
        questions: [
          { id: uuidv4(), text: 'Set clear action items', type: QuestionType.YES_NO },
          { id: uuidv4(), text: 'Scheduled follow-up meeting', type: QuestionType.RANGE },
        ],
      },
    ],
  },
];
