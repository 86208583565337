import { useCallback, useEffect, useRef, useState } from 'react';
import {
  BUFFER_DURATION_THRESHOLD,
  MAX_PACKET_DURATION_THRESHOLD,
  NO_OUTBOUND_PACKET_THRESHOLD,
  OUTBOUND_PACKET_SAMPLE_RATE,
  PACKET_INTERVAL_MS,
} from '../constants';
import { TwilioMediaMessage } from '../types';
import { base64ToUint8Array, generateCallSid, generateStreamSid, playBufferedAudio } from '../utils/audio.utils';
import useAudioInput from './useAudioInput';
import useWebSocket from './useWebSocket';

const useWebCall = () => {
  // State variables
  const [isConnected, setIsConnected] = useState(false); // Websocket connection status
  const [isCalling, setIsCalling] = useState(false); // Call status
  const [startTime, setStartTime] = useState(0); // Start time of the call, that is detected on render
  const [currentTime, setCurrentTime] = useState(0); // Current time of the call in seconds

  // Refs
  const streamSidRef = useRef(generateStreamSid()); // The streamSid to be created for our call
  const callSidRef = useRef(generateCallSid()); // The callSid to be created for our call (After we implement Authentication, they will be generated by our servers)
  const audioPlaybackTimeRef = useRef<number>(0); // Ref to keep track of and set the current playback time
  const muLawBufferRef = useRef<Uint8Array[]>([]); // buffer to play the audio in chunks bigger than 20ms to be processed better with higher quality
  const accumulatedDurationRef = useRef(0); // Accumulated buffer duration in milliseconds
  const lastOutboundTimestampRef = useRef(0); // used to buffer the outbound packets exactly 20ms apart
  const startTimeRef = useRef(0); // used to be detected inside onmessage

  // Initialize useAudioInput hook
  const {
    micPermissionState,
    isConnectedToMic,
    isConnectingToMic,
    startAudioInput,
    resetAudioInput,
    playbackAudioContextRef,
    isMuted,
    mute,
    unmute,
  } = useAudioInput(startTimeRef);

  // Wrapper so it would be easier to pass it to hooks and components
  const playBufferedAudioWrapper = useCallback(() => {
    if (muLawBufferRef.current.length > 0) {
      playBufferedAudio(muLawBufferRef, accumulatedDurationRef, playbackAudioContextRef, audioPlaybackTimeRef);
    }
  }, []);

  // This function is triggered whenever there is outbound audio received to the connect websocket
  const handleConnectMessage = useCallback(
    (message: TwilioMediaMessage) => {
      if (message.event === 'media' && message.media && message.media.track === 'outbound' && message.media.payload) {
        const base64Data = message.media.payload;

        // Decode base64 to Uint8Array
        const muLawData = base64ToUint8Array(base64Data);

        // Add the Uint8Array to the buffer
        muLawBufferRef.current.push(muLawData);

        // Each chunk represents 20ms of audio
        const duration = (muLawData.length / OUTBOUND_PACKET_SAMPLE_RATE) * 1000; // Calculate duration in milliseconds
        accumulatedDurationRef.current += duration;

        // Check if we've reached or exceeded the buffer duration threshold or if the duration of the packet itself is larger
        if (duration >= MAX_PACKET_DURATION_THRESHOLD || accumulatedDurationRef.current >= BUFFER_DURATION_THRESHOLD) {
          playBufferedAudioWrapper();
        }
      } else if (message.event === 'stop' && muLawBufferRef.current.length > 0) {
        // Handle any remaining data in the buffer when the stream stops
        playBufferedAudioWrapper();
      }
    },
    [playBufferedAudioWrapper]
  );

  // This function is triggered whenever there is outbound audio received to the CONNECT websocket also
  const handleStartMessage = useCallback((message: TwilioMediaMessage, startTime: number) => {
    if (message.event === 'media' && message.media && message.media.payload && message.media.track === 'outbound') {
      const timestamp = startTime === 0 ? 0 : Date.now() - startTime;
      message.media.timestamp = timestamp.toString();

      // Adjust timestamp if packets arrive too close together
      const lastOutboundTimestamp = lastOutboundTimestampRef.current;
      if (lastOutboundTimestamp && timestamp - lastOutboundTimestamp <= NO_OUTBOUND_PACKET_THRESHOLD) {
        message.media.timestamp = (lastOutboundTimestamp + PACKET_INTERVAL_MS).toString();
      }

      lastOutboundTimestampRef.current = parseInt(message.media.timestamp);

      // Send adjusted message back to the WebSocket server
      sendStartMessage(message);
    }
  }, []);

  // Function to reset call references
  const resetCallRefs = useCallback(() => {
    resetAudioInput();
    streamSidRef.current = generateStreamSid();
    callSidRef.current = generateCallSid();
    audioPlaybackTimeRef.current = 0;
    muLawBufferRef.current = [];
    accumulatedDurationRef.current = 0;
    lastOutboundTimestampRef.current = 0;

    setIsCalling(false);
    setIsConnected(false);
    setStartTime(0);
  }, [resetAudioInput]);

  // Initialize useWebSocket hook
  const { handleConnect, handleDisconnect, sendConnectMessage, sendStartMessage } = useWebSocket(
    resetCallRefs,
    handleConnectMessage,
    handleStartMessage,
    setIsConnected,
    streamSidRef,
    callSidRef,
    playBufferedAudioWrapper,
    startTimeRef,
    setStartTime
  );

  // Function to stop the call.
  const stopCall = useCallback(() => {
    // Send a 'stop' event message
    const stopMessage = { event: 'stop', streamSid: streamSidRef.current, stop: {} };
    sendConnectMessage(stopMessage);
    sendStartMessage(stopMessage);
    handleDisconnect();
    resetCallRefs();
  }, [handleDisconnect, resetCallRefs, sendConnectMessage, sendStartMessage, resetAudioInput]);

  // Function to start the call.
  const startCall = useCallback(
    async (twilioNumber: string, userId: string) => {
      // Start capturing audio and sending as 'inbound' over both WebSockets.
      setIsCalling(true);
      try {
        await startAudioInput([sendStartMessage, sendConnectMessage], streamSidRef);
        await handleConnect(twilioNumber, userId);
      } catch (error) {
        console.error('Error starting call: ', error);
        stopCall();
      }
    },
    [handleConnect, startAudioInput, sendConnectMessage, sendStartMessage, stopCall]
  );

  // Cleanup on unmount.
  useEffect(() => {
    return () => {
      if (isCalling || isConnected) {
        stopCall();
      }
    };
  }, []);

  // Update call duration
  useEffect(() => {
    if (startTime === 0) {
      setCurrentTime(0);
      return;
    }

    const timer = setInterval(() => {
      const elapsedTime = (Date.now() - startTime) / 1000;
      setCurrentTime(elapsedTime);
    }, 1000);

    return () => clearInterval(timer);
  }, [startTime]);

  return {
    micPermissionState,
    isConnectedToMic,
    isConnectingToMic,
    isConnected,
    isCalling,
    currentTime,
    startCall,
    stopCall,
    isMuted,
    mute,
    unmute,
  };
};

export default useWebCall;
