import { AlertType, Icon } from '../../components';
import { useToast } from '../../hooks';
import useDial from '../../hooks/useDial';
import { PracticeFilterKeys, PracticeProspect } from '../../types';

const useProspectActions = (onClose: () => void, clickedProspect?: PracticeProspect) => {
  const { showToast } = useToast();
  const onDial = useDial(onClose);

  const onCopy = () => {
    const phoneNumber = clickedProspect?.associatedPhoneNumber;
    if (phoneNumber) {
      navigator.clipboard.writeText(phoneNumber);
      showToast({ message: 'Phone number copied to clipboard', type: AlertType.SUCCESS });
      onClose();
    }
  };

  const onShare = () => {
    if (clickedProspect) {
      // Generate the URL based on the current window location.
      const url = new URL(window.location.href);
      // Clear all existing search parameters.
      url.search = '';
      // Set the new query parameter.
      url.searchParams.set(PracticeFilterKeys.PROSPECT, clickedProspect.personaId);

      // Copy the URL to the clipboard.
      navigator.clipboard.writeText(url.toString());
      showToast({ message: 'Link to prospect copied to clipboard', type: AlertType.SUCCESS });
      onClose();
    }
  };

  return [
    {
      label: 'Call',
      icon: Icon.PHONE,
      onClick: () => onDial(clickedProspect),
    },
    {
      label: 'Share',
      icon: Icon.SHARE,
      successMsg: 'Link to practice prospect copied to clipboard',
      onClick: onShare,
    },
    {
      label: 'Copy Phone Number',
      icon: Icon.COPY,
      onClick: onCopy,
    },
  ];
};

export default useProspectActions;
