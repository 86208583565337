import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuth0ClientInstance } from '../context/CustomAuth0Provider';
import { setConnectionStatus } from '../redux/reducers/connectionReducer';
import { AppDispatch } from '../redux/store';
import { SKIP_AUTH_HEADER } from '../constants';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL, // Set the base URL for all queries.
  prepareHeaders: async (headers) => {
    // Skip authentication for requests with 'Skip-Auth' header.
    if (headers.get(SKIP_AUTH_HEADER) === 'true') {
      // Remove custom header before sending the request
      headers.delete(SKIP_AUTH_HEADER);
      return headers;
    }

    const auth0Client = getAuth0ClientInstance();
    const token = await auth0Client.getAccessTokenSilently();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const apiBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const { dispatch } = api as { dispatch: AppDispatch };

  if (!navigator.onLine) {
    // If there's no connection, dispatch an action to update the state
    dispatch(setConnectionStatus(false));
  }

  const result = await baseQuery(args, api, extraOptions);
  return result;
};

export default apiBaseQuery;
