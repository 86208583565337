import * as RadixTabs from '@radix-ui/react-tabs';
import clsx from 'clsx';
import { Alignment, TextColor } from '../../../types';
import { Icons } from '../Icons';
import { Typography, TypographySize } from '../Typography';
import { TabsProps } from './Tabs.types';

const Tabs = ({ activeTab, tabs, setActiveTab, alignment = Alignment.CENTER, className, ghost = false }: TabsProps) => {
  const getTabContentColor = (isActive: boolean) => {
    return isActive || ghost ? TextColor.PRIMARY : TextColor.SECONDARY;
  };

  const getTabBgColor = (isActive: boolean) => {
    if (!isActive) {
      return 'hover:bg-base-200';
    }

    return ghost ? 'bg-base-300' : 'bg-base-0';
  };

  return (
    <RadixTabs.Root
      onValueChange={setActiveTab}
      value={activeTab}
      className={clsx('flex h-full flex-col gap-8', className)}
    >
      <RadixTabs.List className={clsx('flex w-full gap-1', !ghost && 'rounded-lg bg-base-300 p-1')}>
        {tabs.map(({ id, title, disabled, icon }) => {
          const isActive = activeTab === id;
          return (
            <RadixTabs.Trigger
              key={id}
              value={id}
              className={clsx(
                'flex cursor-pointer items-center justify-center gap-2 rounded-lg px-4 py-1',
                alignment === Alignment.CENTER && 'flex-grow',
                getTabBgColor(isActive),
                disabled && 'pointer-events-none'
              )}
            >
              {icon && <Icons icon={icon} color={getTabContentColor(isActive)} />}
              <Typography size={TypographySize.H5} color={getTabContentColor(isActive)}>
                {title}
              </Typography>
            </RadixTabs.Trigger>
          );
        })}
      </RadixTabs.List>
      {tabs.map(
        ({ content, id }) =>
          content && (
            <RadixTabs.Content key={id} value={id} className="h-full">
              {content}
            </RadixTabs.Content>
          )
      )}
    </RadixTabs.Root>
  );
};

export default Tabs;
