import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useWebCall } from '../../../hooks';
import { closeWebCall, WebCallData } from '../../../redux/reducers';
import { ComponentSize } from '../../../types';
import { Divider, DividerType, getMaxTagsAndNotesHeight, Spinner, TagsAndNotesCell } from '../../shared';
import WebCallActions from './WebCallActions';
import WebCallProspect from './WebCallProspect';
import WebCallStatus from './WebCallStatus';
import WebCallUpNext from './WebCallUpNext';

// Use your own Twilio number for testing by passing it to startCall.
// const TWILIO_NUMBER = '+18589464419';

interface WebCallModalProps {
  currWebCall: WebCallData;
  setIsMicAccessModalOpen: (open: boolean) => void;
}

const WebCallModal = ({ currWebCall, setIsMicAccessModalOpen }: WebCallModalProps) => {
  const [endTime, setEndTime] = useState(0);

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  const {
    currentTime,
    isCalling,
    isConnected,
    isConnectingToMic,
    isMuted,
    micPermissionState,
    mute,
    unmute,
    startCall,
    stopCall,
  } = useWebCall();

  const maxTagsHeight = getMaxTagsAndNotesHeight();

  const { currProspect, nextProspect } = currWebCall;

  // Starts a call to the given number.
  const callNumber = useCallback(
    (number: string) => {
      if (!user?.id) return;
      startCall(number, user.id);
    },
    [user?.id, startCall]
  );

  const endCall = useCallback(() => {
    // The current time is reset to 0 when the call ends
    // so we save the end time in a separate state variable to display in the UI.
    setEndTime(currentTime);
    stopCall();
  }, [currentTime, setEndTime]);

  useEffect(() => {
    // Make sure to start the call after the component is mounted.
    const timeoutId = setTimeout(() => callNumber(currProspect.associatedPhoneNumber), 0);
    return () => clearTimeout(timeoutId);
  }, [currProspect, callNumber]);

  // If the user denies microphone permission, close the modal.
  useEffect(() => {
    if (micPermissionState === 'denied') {
      setIsMicAccessModalOpen(true);
      dispatch(closeWebCall());
    }
  }, [micPermissionState, dispatch, setIsMicAccessModalOpen]);

  // End the call when the user unloads the page.
  useEffect(() => {
    const handleBeforeUnload = () => {
      endCall();
      dispatch(closeWebCall());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [endCall, dispatch]);

  if (micPermissionState === null) return null;

  return (
    <div className="fixed inset-0 z-50 flex h-full items-end">
      {/* Overlay */}
      <div
        className="absolute inset-0 bg-neutral bg-opacity-40"
        onClick={isCalling ? undefined : () => dispatch(closeWebCall())}
      />
      {/* Modal */}
      <div className="relative z-50 w-full border-t border-gray-200 bg-base-0 p-8">
        {isConnectingToMic && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Spinner size={ComponentSize.MEDIUM} />
          </div>
        )}
        <div
          className={clsx('flex items-center gap-8', isConnectingToMic && 'invisible')}
          style={{ height: maxTagsHeight }}
        >
          <div className="flex-1">
            <WebCallProspect prospect={currProspect} />
          </div>
          <Divider type={DividerType.VERTICAL} />
          <div className="flex-[1.5]">
            <TagsAndNotesCell selectedTags={currProspect.tags} notes={currProspect.notes} />
          </div>
          <Divider type={DividerType.VERTICAL} />
          <div className="flex-1">
            <WebCallStatus
              currentTime={currentTime}
              endTime={endTime}
              isCalling={isCalling}
              isConnected={isConnected}
              redial={() => callNumber(currProspect.associatedPhoneNumber)}
            />
          </div>
          <Divider type={DividerType.VERTICAL} />
          <div className="flex-[1.25]">
            {isCalling && <WebCallActions endCall={endCall} isMuted={isMuted} mute={mute} unmute={unmute} />}
            {!isCalling && <WebCallUpNext currentProspect={currProspect} nextProspect={nextProspect} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebCallModal;
