export enum QuestionType {
  YES_NO = 'yes_no',
  RANGE = 'range',
}

export interface Question {
  id: string;
  text: string;
  type?: QuestionType;
}

export interface Section {
  id: string;
  name: string;
  questions: Question[];
}

export interface Scorecard {
  id: string;
  name: string;
  creator: string;
  updatedOn: Date;
  sections: Section[];
  creatorAvatarUrl?: string;
  isArchived?: boolean;
  isUsed?: boolean;
}
