import { TextColor } from '../../../types/colors.types';
import { ButtonColor, ButtonGroup, Dialog, Icon, Icons, TextButton, Typography, TypographySize } from '../../shared';

const GIVE_MIC_ACCESS_MODAL_WIDTH = 440;
interface GiveMicAccessModalProps {
  open: boolean;
  onClose: () => void;
  onTryAgain: () => void;
}

const GiveMicAccessModal = ({ open, onClose, onTryAgain }: GiveMicAccessModalProps) => {
  return (
    <Dialog
      isOpen={open}
      onClose={onClose}
      title="Microphone access blocked"
      description="FullyRamped requires microphone access in order to make calls."
      icon={<Icons icon={Icon.CIRCLE_ALERT} color={TextColor.DESTRUCTIVE} />}
      width={GIVE_MIC_ACCESS_MODAL_WIDTH}
    >
      <div className="flex flex-col gap-4">
        <Typography size={TypographySize.H5}>
          Go to your <span className="font-medium">Browser Settings</span> and give FullyRamped microphone access to
          continue.
        </Typography>
        <ButtonGroup>
          <TextButton text="Try again" onClick={onTryAgain} color={ButtonColor.PRIMARY} />
          <TextButton text="Cancel" onClick={onClose} />
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export default GiveMicAccessModal;
