import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../constants';
import {
  CallHistoryFilterKeys,
  CallHistoryFilters,
  DATE_FILTER_KEYS,
  DateOptions,
  PracticeFilterKeys,
  PracticeFilters,
  Roles,
} from '../types';
import useAppSelector from './useAppSelector';

/**
 * Extracts all key-value pairs from the given URLSearchParams object.
 * Split comma-separated values into arrays for each parameter.
 * For the search parameter, we don't split the value into an array.
 */
const getAllParams = (params: URLSearchParams): PracticeFilters | CallHistoryFilters => {
  const entries: Record<string, string | string[]> = {};
  params.forEach((value, key) => {
    entries[key] = key === PracticeFilterKeys.SEARCH ? value : value.split(',');
  });
  return entries;
};

const useGetFiltersFromParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const paramsUpdated = useRef(false);

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const isCallHistoryPage = useMemo(() => location.pathname.includes(AppRoutes.CALL_HISTORY), [location.pathname]);

  const user = useAppSelector((state) => state.auth.user);
  const isSalesRep = useMemo(() => user?.role === Roles.SALES_REP, [user?.role]);

  // Sanitizes the URL parameters to ensure they are valid.
  const sanitizeParams = useCallback(() => {
    paramsUpdated.current = false;

    // Remove invalid keys.
    const validKeys = isCallHistoryPage ? Object.values(CallHistoryFilterKeys) : Object.values(PracticeFilterKeys);
    params.forEach((_, key) => {
      if (!validKeys.includes(key as PracticeFilterKeys & CallHistoryFilterKeys)) {
        params.delete(key);
        paramsUpdated.current = true;
      }
    });

    // Remove the 'caller' filter for Sales Reps if it exists.
    if (params.has(CallHistoryFilterKeys.CALLER) && isSalesRep) {
      params.delete(CallHistoryFilterKeys.CALLER);
      paramsUpdated.current = true;
    }

    // Remove invalid date parameters.
    DATE_FILTER_KEYS.forEach((key) => {
      if (!params.has(key)) return;

      const paramValue = params.get(key) || '';
      const allValues = paramValue.split(',');
      const validValues = allValues.filter((v) => Object.values(DateOptions).includes(v as DateOptions));
      if (validValues.length && validValues.length !== allValues.length) {
        params.set(key, validValues.join(','));
        paramsUpdated.current = true;
      } else if (!validValues.length) {
        params.delete(key);
        paramsUpdated.current = true;
      }
    });
  }, [params, isCallHistoryPage, isSalesRep]);

  const filters = useMemo(() => {
    sanitizeParams();
    return getAllParams(params);
  }, [params, sanitizeParams]);

  useEffect(() => {
    if (paramsUpdated.current) {
      const search = new URLSearchParams(filters as Record<string, string>).toString();
      navigate({ pathname: location.pathname, search }, { replace: true });
    }
  }, [filters, location.pathname, navigate]);

  return filters;
};

export default useGetFiltersFromParams;
