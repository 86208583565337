import { useCallback, useEffect, useRef } from 'react';
import { NO_PACKET_TIMEOUT } from '../constants';
import { TwilioMediaMessage } from '../types';
import usePlayOutboundRing from './usePlayOutboundRing';

const useWebSocket = (
  resetCallRefs: () => void,
  onConnectMessage: (message: TwilioMediaMessage) => void,
  onStartMessage: (message: TwilioMediaMessage, startTime: number) => void,
  setIsConnected: (value: boolean) => void,
  streamSidRef: React.MutableRefObject<string>,
  callSidRef: React.MutableRefObject<string>,
  playBufferedAudio: () => void,
  startTimeRef: React.MutableRefObject<number>,
  setStartTime: (value: number) => void
) => {
  const connectWsRef = useRef<WebSocket | null>(null);
  const startWsRef = useRef<WebSocket | null>(null);

  // Ref for the no-packet timeout timer.
  const connectNoPacketTimerRef = useRef<NodeJS.Timeout | null>(null);
  // Ref for the connection initiation timeout timer.
  const initiateConnectionTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { playRingingSound, pauseRingingSound } = usePlayOutboundRing();

  // Helper function to clear the connection initiation timeout timer.
  const clearInitiateConnectionTimeout = useCallback(() => {
    if (initiateConnectionTimeoutRef.current) {
      clearTimeout(initiateConnectionTimeoutRef.current);
      initiateConnectionTimeoutRef.current = null;
    }
  }, []);

  // Helper function to disconnect the WebSocket connections.
  const handleDisconnect = useCallback(() => {
    // Clear the connection initiation timeout timer to prevent any further connection attempts
    // if the user has aborted the connection before the connection was established.
    clearInitiateConnectionTimeout();

    // Close /connect WebSocket
    if (connectWsRef.current) {
      connectWsRef.current.close();
      connectWsRef.current = null;
    }

    // Close /start WebSocket
    if (startWsRef.current) {
      startWsRef.current.close();
      startWsRef.current = null;
    }

    setIsConnected(false);

    // Clear the no-packet timeout timer
    if (connectNoPacketTimerRef.current) {
      clearTimeout(connectNoPacketTimerRef.current);
      connectNoPacketTimerRef.current = null;
    }

    // Pause the ringing sound in case it is still playing.
    pauseRingingSound();
  }, [pauseRingingSound, setIsConnected]);

  // Helper function to create the 'start' message.
  const createStartMessage = useCallback(
    (twilioNumber: string, userId: string) => ({
      event: 'start',
      streamSid: streamSidRef.current,
      start: {
        streamSid: streamSidRef.current,
        callSid: callSidRef.current,
        tracks: ['inbound'],
        mediaFormat: {
          encoding: 'audio/x-mulaw',
          sampleRate: 8000,
          channels: 1,
        },
        customParameters: {
          userId,
          twilioNumber,
        },
      },
    }),
    []
  );

  const initiateConnection = useCallback(
    (
      twilioNumber: string,
      userId: string,
      resolve: (value: void | PromiseLike<void>) => void,
      reject: (reason?: unknown) => void
    ) => {
      let connectWsOpen = false;
      let startWsOpen = false;

      // Check if both WebSockets are connected and set the start time.
      const checkBothConnected = () => {
        if (connectWsOpen && startWsOpen) {
          setStartTime(new Date().getTime());
          startTimeRef.current = Date.now();
          return resolve();
        }
      };

      // Establish /connect WebSocket.
      connectWsRef.current = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/twilio/connect/`);
      connectWsRef.current.binaryType = 'arraybuffer';

      connectWsRef.current.onopen = () => {
        console.log('/connect WebSocket connection established');
        pauseRingingSound();
        setIsConnected(true);
        connectWsOpen = true;
        checkBothConnected();

        // Send 'start' event for /connect
        const startMessage = createStartMessage(twilioNumber, userId);
        connectWsRef.current?.send(JSON.stringify(startMessage));
      };

      connectWsRef.current.onmessage = (event) => {
        if (typeof event.data === 'string') {
          // Reset the no-packet timer
          if (connectNoPacketTimerRef.current) {
            clearTimeout(connectNoPacketTimerRef.current);
          }
          connectNoPacketTimerRef.current = setTimeout(() => {
            // No packets received for NO_PACKET_TIMEOUT duration
            // Process the remaining audio in the buffer, if any
            playBufferedAudio();
          }, NO_PACKET_TIMEOUT);

          const message = JSON.parse(event.data) as TwilioMediaMessage;
          onStartMessage(message, startTimeRef.current);
          onConnectMessage(message);
        }
      };

      connectWsRef.current.onclose = () => {
        console.log('/connect WebSocket connection closed');
        connectWsOpen = false;
        resetCallRefs();
        handleDisconnect();
        reject(new Error('/connect WebSocket connection closed'));
      };

      connectWsRef.current.onerror = (error) => {
        console.error('/connect WebSocket error:', error);
        connectWsOpen = false;
        resetCallRefs();
        handleDisconnect();
        reject(error);
      };

      // Establish /start WebSocket.
      startWsRef.current = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/twilio/start/`);
      startWsRef.current.binaryType = 'arraybuffer';

      startWsRef.current.onopen = () => {
        console.log('/start WebSocket connection established');
        startWsOpen = true;
        checkBothConnected();

        // Send 'start' event for /start
        const startMessage = createStartMessage(twilioNumber, userId);
        startWsRef.current?.send(JSON.stringify(startMessage));
      };

      startWsRef.current.onclose = () => {
        console.log('/start WebSocket connection closed');
        reject(new Error('/start WebSocket connection closed'));
      };

      startWsRef.current.onerror = (error) => {
        console.error('/start WebSocket error:', error);
        reject(error);
      };
    },
    [onConnectMessage, onStartMessage, setIsConnected, playBufferedAudio, setStartTime, createStartMessage]
  );

  const handleConnect = useCallback(
    (twilioNumber: string, userId: string) => {
      playRingingSound();

      return new Promise<void>((resolve, reject) => {
        initiateConnectionTimeoutRef.current = setTimeout(
          () => initiateConnection(twilioNumber, userId, resolve, reject),
          1000
        );
      }).finally(() => {
        // Cleanup the connection initiation timeout timer.
        clearInitiateConnectionTimeout();
      });
    },
    [initiateConnection, clearInitiateConnectionTimeout]
  );

  const sendConnectMessage = useCallback((message: TwilioMediaMessage) => {
    if (connectWsRef.current?.readyState === WebSocket.OPEN) {
      connectWsRef.current?.send(JSON.stringify(message));
    }
  }, []);

  const sendStartMessage = useCallback((message: TwilioMediaMessage) => {
    if (startWsRef.current?.readyState === WebSocket.OPEN) {
      startWsRef.current?.send(JSON.stringify(message));
    }
  }, []);

  // Cleanup WebSockets on unmount
  useEffect(() => {
    return () => handleDisconnect();
  }, [handleDisconnect]);

  return {
    handleConnect,
    handleDisconnect,
    sendConnectMessage,
    sendStartMessage,
  };
};

export default useWebSocket;
