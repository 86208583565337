import { ButtonColor, ButtonVariant, Icon, IconButton, TextButton, TextInput } from '../../../../shared';
import { Question, Section } from '../../../../../types';
import { v4 as uuidv4 } from 'uuid';
import ScorecardQuestion from './ScorecardQuestion';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvidedDragHandleProps,
  DraggableProvidedDraggableProps,
} from '@hello-pangea/dnd';

interface ScorecardSectionProps {
  section: Section;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  draggableProps?: DraggableProvidedDraggableProps;
  innerRef?: React.Ref<HTMLDivElement>;
  onUpdate?: (updatedSection: Section) => void;
  onRemove?: () => void;
}

const ScorecardSection = ({
  section,
  onUpdate,
  onRemove,
  dragHandleProps,
  draggableProps,
  innerRef,
}: ScorecardSectionProps) => {
  // Add a question to the section
  const handleAddQuestion = () => {
    // ID is a unique identifier for the question to handle reordering in frontend before saving
    const updatedQuestions = [...section.questions, { text: '', type: undefined, id: uuidv4() }];
    onUpdate?.({ ...section, questions: updatedQuestions });
  };

  // Update a question in the section
  const handleUpdateQuestion = (index: number, updatedQuestion: Question) => {
    const updatedQuestions = [...section.questions];
    updatedQuestions[index] = updatedQuestion;
    onUpdate?.({ ...section, questions: updatedQuestions });
  };

  // Remove a question from the section
  const handleRemoveQuestion = (index: number) => {
    const updatedQuestions = section.questions.filter((_, i) => i !== index);
    onUpdate?.({ ...section, questions: updatedQuestions });
  };

  // Handle drag end event
  const handleQuestionDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedQuestions = Array.from(section.questions);
    const [movedQuestion] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, movedQuestion);

    onUpdate?.({ ...section, questions: reorderedQuestions });
  };

  return (
    <div ref={innerRef} {...draggableProps}>
      <DragDropContext onDragEnd={handleQuestionDragEnd}>
        <div className="flex items-start gap-2">
          <div {...dragHandleProps}>
            <IconButton icon={Icon.GRIP_VERTICAL} color={ButtonColor.SECONDARY} variant={ButtonVariant.GHOST} />
          </div>
          <div className="flex w-full flex-col gap-2">
            <TextInput
              placeholder="Section name*"
              onChange={(e) => onUpdate?.({ ...section, name: e.target.value })}
              value={section.name}
            />
            <div className="flex flex-col gap-2 rounded-lg bg-gray-100 p-2">
              {section.questions?.length > 0 && (
                <Droppable
                  droppableId={section.id}
                  renderClone={(provided, _snapshot, rubric) => (
                    <ScorecardQuestion
                      question={section.questions[rubric.source.index]}
                      dragHandleProps={provided.dragHandleProps || undefined}
                      draggableProps={provided.draggableProps || undefined}
                      innerRef={provided.innerRef}
                    />
                  )}
                >
                  {(droppableProvided) => (
                    <div
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                      className="flex flex-col gap-2"
                    >
                      {section.questions?.map((question, index) => (
                        <Draggable draggableId={question.id} index={index} key={question.id}>
                          {(draggableProvided) => (
                            <ScorecardQuestion
                              question={question}
                              dragHandleProps={draggableProvided.dragHandleProps || undefined}
                              draggableProps={draggableProvided.draggableProps || undefined}
                              innerRef={draggableProvided.innerRef}
                              onUpdate={(updatedQuestion) => handleUpdateQuestion(index, updatedQuestion)}
                              onRemove={() => handleRemoveQuestion(index)}
                            />
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              )}
              <TextButton
                text="Add question"
                startIcon={Icon.PLUS}
                onClick={handleAddQuestion}
                fullWidth
                variant={ButtonVariant.OUTLINE}
              />
            </div>
          </div>
          <IconButton
            icon={Icon.TRASH}
            onClick={onRemove}
            color={ButtonColor.DESTRUCTIVE}
            variant={ButtonVariant.GHOST}
          />
        </div>
      </DragDropContext>
    </div>
  );
};

export default ScorecardSection;
