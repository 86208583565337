import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AddCommentPayload,
  ApiTagTypes,
  AppOrganization,
  AppUser,
  ApplyTagsPayload,
  BasePracticeProspect,
  Call,
  CallResponse,
  CallsPayload,
  CallsResponse,
  Candidate,
  CandidatesResponse,
  Comment,
  CommentsResponseData,
  ConfirmUserPhoneNumberPayload,
  CreateCandidatePayload,
  CreateTagPayload,
  CreateTagResponse,
  EditUserPhoneNumberPayload,
  EditUserRolePayload,
  GenerateUploadUrlPayload,
  GenerateUploadUrlResponseData,
  GetPracticeProspectsForSelectPayload,
  InitiateCallPayload,
  InitiateCallResponse,
  InitiateCallResponseData,
  IntercomHmacResponse,
  IntercomHmacResponseData,
  Invite,
  InviteUserPayload,
  InvitesResponse,
  OrgUsersResponse,
  OrganizationResponse,
  Pagination,
  PhoneNumbersResponse,
  PostSuccessResponse,
  PracticeProspect,
  PracticeProspectsPayload,
  PracticeProspectsResponse,
  SendAccessRequestEmailPayload,
  PracticeProspectsSelectResponse,
  Tag,
  TagsResponse,
  UsersSelectResponse,
} from '../types';
import { getUrlWithPaginationAndSorting, parseProspect, transformToAppOrg } from '../utils';
import apiBaseQuery from './apiBaseQuery';
import { SKIP_AUTH_HEADER } from '../constants';

// Setup RTK Query API service with base configurations.
export const api = createApi({
  reducerPath: 'api',
  baseQuery: apiBaseQuery,
  tagTypes: Object.values(ApiTagTypes),
  endpoints: (builder) => ({
    // -- CANDIDATES --
    createCandidate: builder.mutation<PostSuccessResponse, CreateCandidatePayload>({
      query: (candidateDate) => ({
        url: '/candidates/create',
        method: 'POST',
        body: candidateDate,
      }),
      invalidatesTags: [ApiTagTypes.CANDIDATES],
    }),
    deleteCandidate: builder.mutation<PostSuccessResponse, string>({
      query: (candidateId) => ({
        url: `/candidates/${candidateId}/delete`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.CANDIDATES],
    }),
    getCandidates: builder.query<Candidate[], void>({
      query: () => '/candidates',
      transformResponse: (response: CandidatesResponse) => response.data.candidates,
      providesTags: [ApiTagTypes.CANDIDATES],
    }),
    // -- COMMENTS --
    addComment: builder.mutation<PostSuccessResponse, AddCommentPayload>({
      query: (commentData) => ({
        url: `/comments/create`,
        method: 'POST',
        body: commentData,
      }),
      invalidatesTags: [ApiTagTypes.COMMENTS],
    }),
    deleteComment: builder.mutation<PostSuccessResponse, string>({
      query: (commentId) => ({
        url: `/comments/${commentId}/delete`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.COMMENTS],
    }),
    getComments: builder.query<Comment[], string>({
      query: (callSid) => `/comments/${callSid}`,
      transformResponse: (response: CommentsResponseData) => response.data.comments,
      providesTags: [ApiTagTypes.COMMENTS],
    }),
    // -- CURRENT USER --
    getPhoneNumbers: builder.query<string[], void>({
      query: () => '/users/me/phone-numbers',
      transformResponse: (response: PhoneNumbersResponse) => response.data.phoneNumbers,
      providesTags: [ApiTagTypes.PHONE_NUMBERS],
    }),
    generateIntercomHMAC: builder.mutation<IntercomHmacResponseData, string>({
      query: (userId) => ({
        url: `/users/${userId}/generate-intercom-hmac`,
        method: 'POST',
      }),
      transformResponse: (response: IntercomHmacResponse) => response.data,
    }),
    getOrganization: builder.query<AppOrganization, string>({
      query: (orgId) => `/organizations/${orgId}`,
      transformResponse: (response: OrganizationResponse) => transformToAppOrg(response.data.organization),
    }),
    // -- ORG USERS --
    getUsers: builder.query<AppUser[], void>({
      query: () => '/users',
      transformResponse: (response: OrgUsersResponse) => {
        const users = response.data.users;
        return users;
      },
      providesTags: [ApiTagTypes.USERS],
    }),
    disableUser: builder.mutation<PostSuccessResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/users/${id}/disable`,
        method: 'POST',
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        // Invalidate cache after a delay because Auth0 does not update immediately.
        setTimeout(() => {
          dispatch(api.util.invalidateTags([ApiTagTypes.USERS]));
        }, 3000);
      },
    }),
    initiateCall: builder.mutation<InitiateCallResponseData, InitiateCallPayload>({
      query: ({ to }) => ({
        url: `/twilio/initiate-call`,
        method: 'POST',
        body: { to },
      }),
      transformResponse: (response: InitiateCallResponse) => response.data,
    }),
    confirmUserPhoneNumber: builder.mutation<PostSuccessResponse, ConfirmUserPhoneNumberPayload>({
      query: ({ id, phoneNumber }) => ({
        url: `/phone-verification/${id}/verify`,
        method: 'POST',
        body: { phoneNumber },
      }),
      invalidatesTags: [ApiTagTypes.PHONE_NUMBERS],
    }),
    deleteUserPhoneNumber: builder.mutation<PostSuccessResponse, EditUserPhoneNumberPayload>({
      query: ({ id, phoneNumber }) => ({
        url: `/users/${id}/phone-numbers/delete`,
        method: 'POST',
        body: { phoneNumber },
      }),
      invalidatesTags: [ApiTagTypes.PHONE_NUMBERS],
    }),
    editUserRole: builder.mutation<PostSuccessResponse, EditUserRolePayload>({
      query: ({ id, ...editData }) => ({
        url: `/users/${id}/role/edit`,
        method: 'POST',
        body: editData,
      }),
      invalidatesTags: [ApiTagTypes.USERS],
    }),
    enableUser: builder.mutation<PostSuccessResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/users/${id}/enable`,
        method: 'POST',
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        // Invalidate cache after a delay because Auth0 does not update immediately.
        setTimeout(() => {
          dispatch(api.util.invalidateTags([ApiTagTypes.USERS]));
        }, 3000);
      },
    }),
    // -- SETUP --
    onboardUser: builder.mutation<PostSuccessResponse, string>({
      query: (id) => ({
        url: `/users/${id}/onboard`,
        method: 'POST',
      }),
    }),
    finishSetup: builder.mutation<PostSuccessResponse, void>({
      query: () => ({
        url: '/users/me/finish-setup',
        method: 'POST',
      }),
    }),
    // -- CALLS --
    getCall: builder.query<Call, string>({
      query: (id) => ({
        url: `/calls/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: CallResponse) => ({
        ...response.data.call,
        practiceProspect: parseProspect(response.data.call.practiceProspect),
      }),
    }),
    getCalls: builder.mutation<{ calls: Call[]; pagination: Pagination }, CallsPayload>({
      query: ({ pagination, sorting, ...filters }) => ({
        url: getUrlWithPaginationAndSorting('/calls', pagination, sorting),
        method: 'POST',
        body: filters,
      }),
      transformResponse: (response: CallsResponse) => ({
        calls: response.data.calls.map((call) => ({
          ...call,
          practiceProspect: parseProspect(call.practiceProspect),
        })),
        pagination: response.data.pagination,
      }),
    }),
    // -- INVITES --
    getInvites: builder.query<Invite[], void>({
      query: () => '/invite',
      transformResponse: (response: InvitesResponse) => response.data.invitations,
      providesTags: [ApiTagTypes.INVITES],
    }),
    inviteUser: builder.mutation<PostSuccessResponse, InviteUserPayload>({
      query: ({ ...inviteData }) => ({
        url: `/invite`,
        method: 'POST',
        body: inviteData,
      }),
      invalidatesTags: [ApiTagTypes.INVITES],
    }),
    revokeInvitation: builder.mutation<PostSuccessResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/invite/${id}/revoke`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.INVITES],
    }),
    // -- PROSPECTS --
    getPracticeProspects: builder.mutation<
      { prospects: PracticeProspect[]; pagination: Pagination },
      PracticeProspectsPayload
    >({
      query: ({ pagination, sorting, ...filters }) => ({
        url: getUrlWithPaginationAndSorting('/prospects', pagination, sorting),
        method: 'POST',
        body: filters,
      }),
      transformResponse: (response: PracticeProspectsResponse) => ({
        prospects: response.data.prospects.map(parseProspect),
        pagination: response.data.pagination,
      }),
    }),
    applyTags: builder.mutation<PostSuccessResponse, ApplyTagsPayload>({
      query: ({ prospectId, ...tagData }) => ({
        url: `/prospects/${prospectId}/apply-tags`,
        method: 'POST',
        body: tagData,
      }),
    }),
    // -- SELECT --
    getPracticeProspectsForSelect: builder.query<BasePracticeProspect[], GetPracticeProspectsForSelectPayload>({
      query: ({ type, includeJobTitle, includeAssociatedPhoneNumber } = {}) => ({
        url: '/prospects/select',
        params: {
          type,
          includeJobTitle,
          includeAssociatedPhoneNumber,
        },
      }),
      transformResponse: (response: PracticeProspectsSelectResponse) => response.data.prospects,
    }),
    getUsersForSelect: builder.query<{ users: AppUser[]; phoneNumbers?: string[] }, void>({
      query: () => '/users/select',
      transformResponse: (response: UsersSelectResponse) => {
        const users = response.data.users;
        const phoneNumbers = response.data.phoneNumbers;
        return { users, phoneNumbers };
      },
    }),
    // -- UPLOAD MODAL --
    generateUploadUrl: builder.mutation<GenerateUploadUrlResponseData, GenerateUploadUrlPayload>({
      query: ({ name, type, size }) => ({
        url: '/file/generate-upload-url',
        method: 'POST',
        body: { name, type, size },
      }),
      transformResponse: (response: { data: GenerateUploadUrlResponseData }) => response.data,
    }),
    submitLink: builder.mutation<{ message: string }, string>({
      query: (link) => ({
        url: '/url',
        method: 'POST',
        body: { url: link },
      }),
    }),
    uploadFile: builder.mutation<void, { file: File; uploadUrl: string }>({
      query: ({ file, uploadUrl }) => ({
        url: uploadUrl,
        method: 'PUT',
        body: file,
        skipAuth: true,
        headers: {
          'Content-Type': file.type,
          // Skip Auth0 authentication for file uploads
          [SKIP_AUTH_HEADER]: 'true',
        },
      }),
    }),
    // -- TAGS --
    createTag: builder.mutation<CreateTagResponse, CreateTagPayload>({
      query: ({ ...tagData }) => ({
        url: `/tags/create`,
        method: 'POST',
        body: tagData,
      }),
      invalidatesTags: [ApiTagTypes.TAGS],
    }),
    getTags: builder.query<Tag[], void>({
      query: () => '/tags',
      transformResponse: (response: TagsResponse) => response.data.tags,
      providesTags: [ApiTagTypes.TAGS],
    }),
    // -- ACCESS REQUEST --
    sendAccessRequestEmail: builder.mutation<PostSuccessResponse, SendAccessRequestEmailPayload>({
      query: ({ email, role, icp }) => ({
        url: '/emails/send-access-request-email',
        method: 'POST',
        body: {
          email,
          role,
          icp,
        },
        headers: {
          [SKIP_AUTH_HEADER]: 'true',
        },
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useCreateCandidateMutation,
  useDeleteCandidateMutation,
  useGetCandidatesQuery,
  useAddCommentMutation,
  useDeleteCommentMutation,
  useGetCommentsQuery,
  useDisableUserMutation,
  useInitiateCallMutation,
  useConfirmUserPhoneNumberMutation,
  useDeleteUserPhoneNumberMutation,
  useEditUserRoleMutation,
  useEnableUserMutation,
  useGetCallQuery,
  useGetCallsMutation,
  useGetInvitesQuery,
  useGetPhoneNumbersQuery,
  useGenerateIntercomHMACMutation,
  useGetPracticeProspectsMutation,
  useGetPracticeProspectsForSelectQuery,
  useGetUsersQuery,
  useGetUsersForSelectQuery,
  useInviteUserMutation,
  useOnboardUserMutation,
  useFinishSetupMutation,
  useRevokeInvitationMutation,
  useGenerateUploadUrlMutation,
  useSubmitLinkMutation,
  useUploadFileMutation,
  useGetOrganizationQuery,
  useGetTagsQuery,
  useApplyTagsMutation,
  useCreateTagMutation,
  useSendAccessRequestEmailMutation,
} = api;
