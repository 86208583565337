import { BaseColor } from '../../../types';

type DividerColor = BaseColor.BASE_200 | BaseColor.BASE_300;

/** Enum for the type of divider, either horizontal or vertical. */
export enum DividerType {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

/** Defines properties for the Divider component. */
export interface DividerProps {
  /** Optional custom classes */
  className?: string;
  /** Optional divider color */
  color?: DividerColor;
  // Optional text to display within the divider.
  text?: string;
  // The type of the divider, either horizontal or vertical.
  type?: DividerType;
}
