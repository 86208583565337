import { useCallback } from 'react';
import { useInitiateCallMutation } from '../services';

const COUNTRY_CODE_REQUIRED_ERROR_MSG = 'Country code is required';
const EMPTY_PHONE_NUMBER_ERROR_MSG = 'Required fields cannot be empty';
const INVALID_PHONE_NUMBER_ERROR_MSG = 'Invalid phone number';
const VERIFICATION_FAILED_ERROR_MSG = 'Cannot verify phone number';

const useVerifyCallActions = () => {
  const [runInitiateCall, { isLoading }] = useInitiateCallMutation();

  // Handler for the "Verify" button click.
  // 1. Validate form fields.
  // 2. Initiate a phone verification call.
  // 3. If the call is successful, show the phone verification modal.
  const initiateCall = useCallback(
    async (countryCode: string, phoneNumber: string) => {
      if (!phoneNumber.length) {
        return { error: EMPTY_PHONE_NUMBER_ERROR_MSG };
      }

      if (countryCode === '+') {
        return { error: COUNTRY_CODE_REQUIRED_ERROR_MSG };
      }

      //Regex check for invalid phone numbers
      const newPhoneNumber = countryCode + phoneNumber;
      if (!/^\+\d{10,15}$/.test(newPhoneNumber)) {
        return { error: INVALID_PHONE_NUMBER_ERROR_MSG };
      }

      // Initiate a phone verification call.
      const { data, error } = await runInitiateCall({ to: newPhoneNumber });

      // If the call is successful, show the phone verification modal.
      if (data) {
        return { data };
      }

      if ('status' in error) {
        if (error.status === 409) {
          return { error: 'This phone number is already associated with another user' };
        }

        if (error.status === 400) {
          if ('data' in error && typeof error.data === 'object' && error.data && 'message' in error.data) {
            return { error: error.data.message as string };
          }
        }
      }

      return { error: VERIFICATION_FAILED_ERROR_MSG };
    },
    [runInitiateCall]
  );

  return { initiateCall, isLoading };
};

export default useVerifyCallActions;
