import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { ComponentSize } from '../../../../types';
import { Icon, Icons } from '../../Icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../Tooltip';
import { Typography } from '../../Typography';
import { BUTTON_SIZE } from '../Button.constants';
import '../Button.css';
import { ButtonColor, ButtonVariant } from '../Button.types';
import { getButtonClass } from '../Button.utils';
import ButtonContent from '../ButtonContent';
import useButtonHandlers from '../useButtonHandlers';
import { TextButtonProps } from './TextButton.types';

const TextButton = ({
  className,
  active = false,
  text,
  color = ButtonColor.PRIMARY,
  disabled = false,
  fullWidth = false,
  endIcon,
  startIcon,
  loading,
  size = ComponentSize.SMALL,
  tooltip,
  variant = ButtonVariant.FILLED,
  onClick,
  onHover,
  onKeyDown,
}: TextButtonProps) => {
  const shouldDisable = disabled || loading || !onClick;

  const { hover, handleOnClick, handleOnMouseEnter, handleOnMouseLeave } = useButtonHandlers({
    onClick,
    onHover,
    onKeyDown,
  });

  const buttonClass = useMemo(
    () => getButtonClass({ color, isActive: active, isDisabled: shouldDisable, isHovered: hover, variant }),
    [active, color, hover, shouldDisable, variant]
  );

  const renderIcon = useCallback(
    (icon: Icon) => <Icons icon={icon} color={buttonClass.text} size={size} />,
    [buttonClass.text, size]
  );

  return (
    <Tooltip>
      <TooltipTrigger fullWidth={fullWidth}>
        <div
          className={clsx(
            'btn relative',
            fullWidth ? 'btn-block' : 'w-fit',
            BUTTON_SIZE[size],
            buttonClass.bg,
            className
          )}
          onClick={handleOnClick}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
          <ButtonContent loading={loading}>
            {startIcon && renderIcon(startIcon)}
            <Typography color={buttonClass.text} noWrap>
              {text}
            </Typography>
            {endIcon && renderIcon(endIcon)}
          </ButtonContent>
        </div>
      </TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};

export default TextButton;
