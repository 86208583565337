import { FilterOptionsAndSelections, useGetFiltersFromParams, useUpdateFilterParams } from '../../hooks';
import { PracticeFilterKeys, PracticeFilters as PracticeFiltersType } from '../../types';
import { parseOptionalSelectOption } from '../../utils';
import { SidebarDateFilter, SidebarSearchFilter, SidebarSelectFilter } from './SidebarFilterFields';

interface PracticeFiltersProps {
  accountOptionsAndSelections: FilterOptionsAndSelections;
  prospectOptionsAndSelections: FilterOptionsAndSelections;
  tagOptionsAndSelections: FilterOptionsAndSelections;
}

const PracticeFilters = ({
  accountOptionsAndSelections,
  prospectOptionsAndSelections,
  tagOptionsAndSelections,
}: PracticeFiltersProps) => {
  // Retrieve filters from URL parameters.
  const filters: PracticeFiltersType = useGetFiltersFromParams();

  // Hook to update filter parameters in the URL.
  const updateFilterParams = useUpdateFilterParams();

  return (
    <>
      {/* Search filter for prospects */}
      <SidebarSearchFilter
        onSearchChange={(newValue) => updateFilterParams(PracticeFilterKeys.SEARCH, newValue)}
        placeholder="Search keyword"
        value={filters.search}
      />
      {/* Prospect filter */}
      <SidebarSelectFilter
        title="Prospect"
        placeholder="Select prospect"
        onChange={(newValue) => updateFilterParams(PracticeFilterKeys.PROSPECT, newValue)}
        {...prospectOptionsAndSelections}
      />
      {/* Prospect Account filter */}
      <SidebarSelectFilter
        title="Prospect account"
        placeholder="Select prospect account"
        onChange={(newValue) => updateFilterParams(PracticeFilterKeys.PROSPECT_ACCOUNT, newValue)}
        {...accountOptionsAndSelections}
      />
      {/* Tags filter */}
      <SidebarSelectFilter
        title="Tags"
        placeholder="Select tag"
        onChange={(newValue) => updateFilterParams(PracticeFilterKeys.TAGS, newValue)}
        {...tagOptionsAndSelections}
      />
      {/* Date Created filter */}
      <SidebarDateFilter
        title="Date created"
        onChange={(newValue) => updateFilterParams(PracticeFilterKeys.DATE_CREATED, newValue)}
        selected={parseOptionalSelectOption(filters.dateCreated)}
      />
    </>
  );
};

export default PracticeFilters;
