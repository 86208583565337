import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { CALL_SPEAKER_TO_LABEL, SINGLE_AUDIO_WAVE_HEIGHT } from '../../constants';
import { useAppSelector } from '../../hooks';
import { useGetCallQuery } from '../../services';
import { CallSpeaker, ComponentSize } from '../../types';
import { formatDuration } from '../../utils';
import { Divider, DividerType, Spinner, Typography, TypographySize } from '../shared';
import ActionButtons from './ActionButtons';
import CallerSection from './CallerSection';
import CommentsPanel from './CommentsPanel/';
import ControlButtons from './ControlButtons';
import MediaControls from './MediaControls';
import useMediaPlayerSeek from './useMediaPlayerSeek';

// Mapping of speaker types to their respective colors.
const SPEAKER_TO_COLOR: Record<CallSpeaker, string> = {
  [CallSpeaker.CUSTOMER]: '#044934',
  [CallSpeaker.AGENT]: '#0AAD7C',
};

interface MediaPlayerProps {
  callSid: string;
  closable?: boolean;
}

const MediaPlayer = ({ callSid, closable }: MediaPlayerProps) => {
  const waveformRef = useRef<HTMLDivElement | null>(null);
  const waveSurfer = useRef<WaveSurfer | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  // Fetch the call data using the provided callSid.
  const { data: call } = useGetCallQuery(callSid);

  const autoPlay = useAppSelector((state) => state.callHistory.currentCallDetails?.autoPlay);

  // Custom hook to handle seeking within the media player based on query parameters or Redux state.
  useMediaPlayerSeek(callSid, duration, waveSurfer);

  // Renders the legend element for a speaker.
  const renderLegendElement = useCallback(
    (speaker: CallSpeaker) => (
      <div className="flex items-center gap-2">
        <div className="inline-block h-4 w-4 rounded-full" style={{ backgroundColor: SPEAKER_TO_COLOR[speaker] }} />
        <Typography size={TypographySize.H5}>{CALL_SPEAKER_TO_LABEL[speaker]}</Typography>
      </div>
    ),
    []
  );

  // Effect hook to initialize WaveSurfer and handle audio loading.
  useEffect(() => {
    if (!waveformRef.current || !call?.audioPath) return;

    // Create a WaveSurfer instance.
    waveSurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      dragToSeek: true,
      waveColor: '#C5CBC9',
      height: SINGLE_AUDIO_WAVE_HEIGHT,
      barWidth: 2,
      barGap: 1.5,
      barRadius: 1.5,
      normalize: true,
      autoplay: !!autoPlay,
      cursorColor: '#C5CBC9',
      splitChannels: [
        { progressColor: SPEAKER_TO_COLOR[CallSpeaker.AGENT], barAlign: 'bottom' },
        { progressColor: SPEAKER_TO_COLOR[CallSpeaker.CUSTOMER], barAlign: 'top' },
      ],
    });

    // Error handler for WaveSurfer.
    waveSurfer.current.on('error', (error) => {
      console.error('WaveSurfer error:', error);
      setIsLoading(false);
    });

    // Set the duration when the audio is ready.
    waveSurfer.current.on('ready', () => {
      if (waveSurfer.current) {
        setDuration(waveSurfer.current.getDuration());
        setIsLoading(false);
      }
    });

    // Update the current time as the audio plays.
    waveSurfer.current.on('audioprocess', () => {
      if (waveSurfer.current) {
        setCurrentTime(waveSurfer.current.getCurrentTime() || 0);
      }
    });

    // Updates the current time as the audio seeks.
    waveSurfer.current.on('seeking', function (position) {
      if (waveSurfer.current) {
        setCurrentTime(position);
      }
    });

    // Restarts the audio from the beginning on finish.
    waveSurfer.current.on('finish', () => {
      if (waveSurfer.current) {
        waveSurfer.current.seekTo(0);
      }
    });

    // Handles loading the audio file.
    const loadAudio = () => {
      if (!call.audioPath) return;
      try {
        waveSurfer.current?.load(call?.audioPath);
      } catch (error) {
        console.error('WaveSurfer load error:', error);
      }
    };

    // Load the audio after the component mounts.
    setTimeout(loadAudio, 0);

    // Cleanup on component unmount.
    return () => {
      if (waveSurfer.current) {
        try {
          waveSurfer.current.destroy();
        } catch (error) {
          console.error('Error destroying WaveSurfer instance:', error);
        }
      }
      setIsLoading(true);
      setCurrentTime(0);
      setDuration(0);
    };
  }, [call?.audioPath]);

  // Add event listener for space bar to play/pause the audio.
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === 'Space' && waveSurfer.current) {
        waveSurfer.current.playPause();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  // Return null if no call is selected (ie MediaPlayer should be closed).
  if (!call) return null;

  return (
    <div className="relative flex items-center gap-8 border-t border-gray-200 bg-base-0 px-8 py-12 shadow-lg">
      <CallerSection call={call} />
      <Divider type={DividerType.VERTICAL} />
      <div className="relative flex flex-grow flex-col items-center">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Spinner size={ComponentSize.MEDIUM} />
          </div>
        )}
        <div className={clsx('flex w-full flex-col gap-2', isLoading && 'invisible')}>
          <CommentsPanel duration={duration} callSid={callSid} />
          <div
            ref={waveformRef}
            id="waveform"
            className="w-full"
            style={{ height: SINGLE_AUDIO_WAVE_HEIGHT * 2 }}
          ></div>
          <div className="flex w-full justify-between">
            <Typography className="w-10 text-xs" size={TypographySize.CAPTION}>
              {formatDuration(currentTime)}
            </Typography>
            <Typography className="w-10 text-right text-xs" size={TypographySize.CAPTION}>
              {formatDuration(duration)}
            </Typography>
          </div>
        </div>
        <div className={clsx('relative flex w-full items-center', isLoading && 'invisible')}>
          <div className="absolute left-0 flex gap-4">
            {renderLegendElement(CallSpeaker.AGENT)}
            {renderLegendElement(CallSpeaker.CUSTOMER)}
          </div>
          <div className="flex flex-grow justify-center">
            <MediaControls waveSurfer={waveSurfer} currentTime={currentTime} duration={duration} />
          </div>
          {waveSurfer.current && (
            <div className="absolute right-0">
              <ActionButtons
                waveSurfer={waveSurfer.current}
                callSid={callSid}
                processingStatus={call.processingStatus}
                currentTime={currentTime}
              />
            </div>
          )}
        </div>
      </div>
      {closable && <ControlButtons callSid={callSid} processingStatus={call.processingStatus} />}
    </div>
  );
};

export default MediaPlayer;
