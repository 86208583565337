import { AppOrganization, DBOrganization } from '../types';

export const transformToAppOrg = (org: DBOrganization): AppOrganization => {
  const { displayName, ...rest } = org;
  return {
    ...rest,
    name: displayName,
    // unnecessary but just a precaution
    createdAt: new Date(org.createdAt),
    updatedAt: new Date(org.updatedAt),
    renewalDate: new Date(org.renewalDate),
  };
};
