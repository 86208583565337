import { useEffect } from 'react';

// Custom hook to observe element resize.
const useObserveElementResize = (
  // Reference to the element to be observed.
  ref: React.RefObject<HTMLElement>,
  // Callback function to handle resize.
  callback: (entries: ResizeObserverEntry[]) => void
) => {
  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    // Create a ResizeObserver instance.
    const resizeObserver = new ResizeObserver((entries) => callback(entries));

    // Start observing the element.
    resizeObserver.observe(element);

    // Cleanup on component unmount.
    return () => resizeObserver.disconnect();
  }, [callback]);
};

export default useObserveElementResize;
