import { Dispatch, SetStateAction, useState } from 'react';
import {
  ButtonColor,
  ButtonVariant,
  Dialog,
  DialogType,
  Divider,
  Icon,
  Icons,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../../../shared';
import { TextButton, TextInput } from '../../../../shared';
import { ComponentSize } from '../../../../../types';

interface ScorecardInUseModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  initialScorecardName: string;
  onConfirm: (newName: string) => void;
}

const ScorecardInUseModal = ({ isOpen, setIsOpen, initialScorecardName, onConfirm }: ScorecardInUseModalProps) => {
  const [newScorecardName, setNewScorecardName] = useState(initialScorecardName);

  const handleConfirm = () => {
    onConfirm(newScorecardName);
    setIsOpen(false);
  };

  const handleClose = () => {
    setNewScorecardName(initialScorecardName);
    setIsOpen(false);
  };

  return (
    <Dialog isOpen={isOpen} onClose={handleClose} type={DialogType.CONFIRM} className="p-8">
      <div className="flex flex-col gap-4">
        <div className="flex gap-2">
          <Icons icon={Icon.INFO} color="text-info" size={ComponentSize.LARGE} />
          <Typography weight={TypographyWeight.SEMI_BOLD} size={TypographySize.H2}>
            Scorecard in use
          </Typography>
        </div>
        <Typography size={TypographySize.H5}>
          This scorecard is in use. To save your changes as a new scorecard, provide a name below.
        </Typography>
        <div className="flex flex-col gap-2">
          <Typography>Scorecard name</Typography>
          <TextInput value={newScorecardName} onChange={(e) => setNewScorecardName(e.target.value)} />
        </div>
        <Divider />
        <div className="flex justify-end gap-2">
          <TextButton text="Cancel" onClick={handleClose} variant={ButtonVariant.GHOST} color={ButtonColor.SECONDARY} />
          <TextButton text="Create" onClick={handleConfirm} />
        </div>
      </div>
    </Dialog>
  );
};

export default ScorecardInUseModal;
