import { ComponentSize, TagColor } from '../../../types';
import { Icon } from '../Icons';

// Maps to DaisyUI Badge classes.
export enum BadgeColor {
  DEFAULT = 'badge-default', // Not an actual DaisyUI class. Just a placeholder.
  NEUTRAL = 'badge-neutral',
  PRIMARY = 'badge-primary',
  SECONDARY = 'badge-secondary',
  ACCENT = 'badge-accent',
  ERROR = 'badge-error',
  SUCCESS = 'badge-success',
  GHOST = 'badge-ghost',
}

export interface BadgeProps {
  label: string;
  color?: BadgeColor;
  dotColor?: TagColor | string;
  icon?: Icon;
  maxWidth?: string | number;
  outline?: boolean;
  showDot?: boolean;
  size?: ComponentSize;
  onClick?: () => Promise<void> | void;
}
