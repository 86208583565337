import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useCallback } from 'react';
import { AlertType } from '../components';
import { ApiResponse, PostErrorResponse } from '../types';
import useToast from './useToast';

const useHandleApiResponse = (hideToast = false) => {
  const { showToast } = useToast();

  const handleApiResponse = useCallback(
    async ({
      response,
      errorMsg,
      successMsg,
      onError,
      onSuccess,
    }: {
      response: ApiResponse;
      errorMsg: string;
      successMsg?: string;
      onError?: (error: FetchBaseQueryError) => void;
      onSuccess?: () => Promise<void> | void;
    }) => {
      if (response.error) {
        const error = response.error as FetchBaseQueryError;
        if (onError) onError(error);
        if (typeof error.data === 'object' && error.data && 'message' in error.data) {
          console.error(`${errorMsg}: `, (error.data as PostErrorResponse).message);
        }
        if (!hideToast) showToast({ message: errorMsg, type: AlertType.ERROR });
        return;
      }

      if (response.data) {
        if (onSuccess) {
          await onSuccess();
        }
        if (!hideToast) showToast({ message: successMsg ?? response.data.message, type: AlertType.SUCCESS });
      }
    },
    [showToast]
  );

  return handleApiResponse;
};

export default useHandleApiResponse;
