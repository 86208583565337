import React from 'react';
import { ButtonColor, ButtonVariant } from '../Button';
import TextButton from '../Button/TextButton/TextButton';
import { ButtonGroupProps } from './ButtonGroup.types';

const ButtonGroup = ({ children, loading }: ButtonGroupProps) => {
  return (
    <div className="flex flex-row-reverse gap-2">
      {React.Children.map(children, (child, index) => {
        // Determine the button style type based on its index in the array.
        let variant = ButtonVariant.GHOST;
        if (index === 0) {
          variant = ButtonVariant.FILLED;
        } else if (index === 1 && children.length > 2) {
          variant = ButtonVariant.OUTLINE;
        }

        return (
          <TextButton
            variant={variant}
            color={child.props.destructive ? ButtonColor.DESTRUCTIVE : ButtonColor.SECONDARY}
            loading={index === 0 && loading ? true : false}
            disabled={index !== 0 && loading ? true : child.props.disabled}
            {...child.props}
          />
        );
      })}
    </div>
  );
};

export default ButtonGroup;
