/**
 * Enum for component alignment.
 */
export enum Alignment {
  CENTER = 'center',
  LEFT = 'left',
}

/**
 * Enum for component sizes.
 * Provides a set of predefined sizes for UI components.
 */
export enum ComponentSize {
  X_SMALL = 'x-small',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

/**
 * Enum for component types.
 * Defines different types of UI components for styling and behavior.
 */
export enum ComponentType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  QUATERNARY = 'quaternary',
  DESTRUCTIVE = 'destructive',
}

/**
 * Type for mouse position coordinates.
 * Represents the x and y coordinates of the mouse pointer.
 */
export type MousePosition = {
  x: number;
  y: number;
};

/**
 * Enum for dropdown placement.
 * Defines the possible positions for a dropdown menu relative to its trigger element.
 */
export enum DropdownPlacement {
  BOTTOM = 'bottom',
  TOP = 'top',
  RIGHT = 'right',
  LEFT = 'left',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end',
}

/**
 * Type for tooltip content.
 * Represents either a simple React node for the tooltip content,
 * or an object with content and placement properties.
 *
 * The object form allows for more precise control over tooltip positioning,
 * while the React node form provides a simpler way to specify content with default positioning.
 */
export type TooltipType =
  | {
      content: React.ReactNode;
      placement: DropdownPlacement;
    }
  | React.ReactNode;

export type TypographyMaxLines = 1 | 2 | 3 | 4 | 5 | 6;
