import { useEffect, useRef, useState } from 'react';
import { Badge, BadgeColor, Typography, TypographySize } from '../../components';
import { AppRoutes } from '../../constants';
import { useUpdateFilterParams, useWindowResize } from '../../hooks';
import { CallHistoryFilterKeys, DropdownPlacement, Tag, TextColor } from '../../types';

interface CallDetailsTagsProps {
  tags: Tag[];
}

const CallDetailsTags = ({ tags }: CallDetailsTagsProps) => {
  const [hiddenTags, setHiddenTags] = useState<Tag[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);
  const tagsRef = useRef<(HTMLDivElement | null)[]>([]);

  const updateFilterParams = useUpdateFilterParams();
  const windowWidth = useWindowResize();

  const handleTagClick = (tagId: string) => {
    updateFilterParams(CallHistoryFilterKeys.TAGS, tagId, AppRoutes.CALL_HISTORY);
  };

  const showMoreLabel = !!hiddenTags.length;

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current && tagsRef.current.length > 0) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const newHiddenTags: Tag[] = [];

        tagsRef.current.forEach((tagRef, index) => {
          if (tagRef) {
            const tagRect = tagRef.getBoundingClientRect();
            if (tagRect.left < containerRect.left) {
              newHiddenTags.push(tags[index]);
            }
          }
        });

        setHiddenTags(newHiddenTags);
      }
    };

    checkOverflow();
    // We listen for changes to the show more label to ensure that the hidden tags are detected correctly.
  }, [tags, windowWidth, showMoreLabel]);

  return (
    <div ref={containerRef} className="flex items-center justify-end gap-2 overflow-hidden">
      {tags.map((tag, index) => (
        <div
          key={tag.id}
          ref={(el) => (tagsRef.current[index] = el)}
          // Hide tag by changing opacity to maintain its position in the DOM
          // This allows for accurate overflow detection and smooth transitions
          className={hiddenTags.find((hiddenTag) => hiddenTag.id === tag.id) ? 'opacity-0' : ''}
        >
          <Badge
            label={tag.name}
            dotColor={tag.color}
            color={BadgeColor.GHOST}
            showDot
            onClick={() => handleTagClick(tag.id)}
          />
        </div>
      ))}
      {showMoreLabel && (
        <Typography
          color={TextColor.SECONDARY}
          size={TypographySize.CAPTION}
          noWrap
          tooltip={{
            content: (
              <div className="flex flex-col">
                {hiddenTags.map((tag) => (
                  <span key={tag.id}>• {tag.name}</span>
                ))}
              </div>
            ),
            placement: DropdownPlacement.BOTTOM,
          }}
        >
          +{hiddenTags.length} more
        </Typography>
      )}
    </div>
  );
};

export default CallDetailsTags;
