import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { PaginationState } from '@tanstack/react-table';
import { CustomSortingState, PostErrorResponse } from '../types';

export const checkInviteUserError = (error: FetchBaseQueryError): string | undefined => {
  const fetchError = error.data as PostErrorResponse;
  if (!fetchError.message) return;

  if (fetchError.message.includes('Validation error')) {
    return 'Invalid email';
  } else {
    return fetchError.message;
  }
};

export const getUrlWithPaginationAndSorting = (
  url: string,
  pagination?: PaginationState,
  sorting?: CustomSortingState
) => {
  const params = new URLSearchParams();

  // let paginationQuery: string | undefined = undefined;
  if (pagination) {
    params.set('page', pagination.pageIndex.toString());
    params.set('perPage', pagination.pageSize.toString());
  }

  // let sortingQuery: string | undefined = undefined;
  if (sorting) {
    params.set('sortBy', sorting.sortBy);
    params.set('sortOrder', sorting.sortOrder);
  }

  return `${url}?${params.toString()}`;
};
