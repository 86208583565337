import React from 'react';
import { Icon, TextButton, Typography, TypographySize, TypographyWeight } from '../../../shared';

interface TableHeaderProps {
  title: string;
  children?: React.ReactNode;
  handleCreateNew?: () => void;
}

const TableHeader = ({ title, children, handleCreateNew }: TableHeaderProps) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
          {title}
        </Typography>
        {handleCreateNew && <TextButton startIcon={Icon.PLUS} text="Create new" onClick={handleCreateNew} />}
      </div>
      {children}
    </div>
  );
};

export default TableHeader;
