import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { DUMMY_SCORECARDS } from '../../../../../constants';
import { useAppSelector } from '../../../../../hooks';
import { Scorecard } from '../../../../../types';
import TableHeader from '../TableHeader';
import ScorecardForm from './ScorecardForm';
import ScorecardsTable from './ScorecardsTable';

const ScorecardsTab = () => {
  // TODO: Fetch scorecards from backend
  const [scorecards, setScorecards] = useState<Scorecard[]>(DUMMY_SCORECARDS);
  // State to store if we are updating a scorecard
  const [isScorecardFormOpen, setIsScorecardFormOpen] = useState(false);
  // State to store the scorecard being edited
  const [editingScorecard, setEditingScorecard] = useState<Scorecard | undefined>(undefined);
  // State to scroll to the top of the table when a new scorecard is duplicated
  const [shouldScrollToTop, setShouldScrollToTop] = useState(false);

  const user = useAppSelector((state) => state.auth.user);

  // Ref to the table
  const tableRef = useRef<HTMLDivElement>(null);

  // Scroll to the top of the table when a new scorecard is duplicated
  useEffect(() => {
    if (shouldScrollToTop && tableRef.current) {
      tableRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setShouldScrollToTop(false);
    }
  }, [shouldScrollToTop, scorecards]);

  const handleCreateNewScorecard = () => setIsScorecardFormOpen(true);

  // Handler on editing a scorecard
  const handleEditScorecard = (scorecard: Scorecard) => {
    setEditingScorecard(scorecard);
    setIsScorecardFormOpen(true);
  };

  // Handler to reset the editing scorecard state
  const handleResetEditingScorecard = () => {
    setIsScorecardFormOpen(false);
    setEditingScorecard(undefined);
  };

  // Handler on saving a scorecard
  const handleSaveScorecard = (updatedScorecard: Scorecard) => {
    if (!user || !user.name) return;

    setScorecards((prevScorecards) => {
      // Remove the existing scorecard if it's being edited
      const filteredScorecards = editingScorecard
        ? prevScorecards.filter((sc) => sc.id !== updatedScorecard.id)
        : prevScorecards;

      if (editingScorecard) {
        if (updatedScorecard.isArchived) {
          // If archiving, add to the bottom of the list
          return [...filteredScorecards, updatedScorecard];
        } else {
          // If editing (not archiving), add to the top of the list
          return [updatedScorecard, ...filteredScorecards];
        }
      } else {
        // Creating new scorecard, add to the top of the list
        const newScorecard = {
          ...updatedScorecard,
          id: uuidv4(),
          creator: user.name || '',
          creatorAvatarUrl: user.picture || '',
          updatedOn: new Date(),
        };
        return [newScorecard, ...filteredScorecards];
      }
    });

    handleResetEditingScorecard();
  };

  // Handler on duplicating a scorecard
  const handleDuplicateScorecard = (scorecard: Scorecard) => {
    if (!user || !user.name) return;

    const duplicatedScorecard: Scorecard = {
      ...scorecard,
      id: uuidv4(), // Generate a new ID for the duplicated scorecard
      creator: user.name,
      creatorAvatarUrl: user.picture,
      updatedOn: new Date(),
      isArchived: false,
      isUsed: false,
    };

    setScorecards([duplicatedScorecard, ...scorecards]);

    // Reset the editing scorecard state
    handleResetEditingScorecard();

    // Scroll to the top of the table
    setShouldScrollToTop(true);
  };

  // Handler on deleting a scorecard
  const handleDeleteScorecard = (scorecardId: string) => {
    setScorecards(scorecards.filter((sc) => sc.id !== scorecardId));
    handleResetEditingScorecard();
  };

  // Render the scorecard form if we are updating a scorecard
  if (isScorecardFormOpen) {
    return (
      <ScorecardForm
        onBack={handleResetEditingScorecard}
        onSave={handleSaveScorecard}
        scorecard={editingScorecard}
        onDelete={handleDeleteScorecard}
        onDuplicate={handleDuplicateScorecard}
      />
    );
  }

  // Render the scorecards table
  return (
    <div className="flex h-full w-full flex-col gap-8">
      <TableHeader title="Scorecard templates" handleCreateNew={handleCreateNewScorecard} />
      <ScorecardsTable
        scorecards={scorecards}
        onEditScorecard={handleEditScorecard}
        onDuplicateScorecard={handleDuplicateScorecard}
        onCreateNewScorecard={handleCreateNewScorecard}
      />
    </div>
  );
};

export default ScorecardsTab;
