import { useCallback } from 'react';
import { useSubmitLinkMutation } from '../services';
import { isValidURL } from '../utils';

const SUBMIT_LINK_ERROR_MSG = 'Failed to submit link';

const useSubmitLink = () => {
  const [submitLink, { isLoading }] = useSubmitLinkMutation();

  // Handler function to manage link submission
  const handleLinkSubmit = useCallback(
    async (linkInput: string) => {
      if (!linkInput) {
        return { error: 'Please enter a URL' };
      }

      if (!isValidURL(linkInput)) {
        return { error: 'Please enter a valid URL' };
      }

      try {
        await submitLink(linkInput);
      } catch (error) {
        console.error(`${SUBMIT_LINK_ERROR_MSG}: `, error);
        return { error: SUBMIT_LINK_ERROR_MSG };
      }
    },
    [submitLink]
  );

  return { submitLink: handleLinkSubmit, isLoading };
};

export default useSubmitLink;
