// Maximum number of rows to display before overflow.
export const MAX_ROWS = 2;
// Maximum tag width before overflow.
export const MAX_TAG_WIDTH = 180;
// Tag height.
export const TAG_HEIGHT = 16;
// Gap between tags.
export const TAG_GAP = 4;
// Notes height.
export const NOTES_HEIGHT = 16;
