import MuLaw from 'mulaw-js';
import { OUTBOUND_PACKET_SAMPLE_RATE } from '../constants';

function muLawToLinear(muLawData: Uint8Array): Int16Array {
  return MuLaw.decode(muLawData);
}

export function generateStreamSid() {
  return 'WS' + Math.random().toString(36).substring(2, 32);
}

export function generateCallSid() {
  return 'CA' + Math.random().toString(36).substring(2, 32);
}

export function applyNoiseGate(inputData: Float32Array, threshold: number = 0.01): Float32Array {
  const outputData = new Float32Array(inputData.length);
  for (let i = 0; i < inputData.length; i++) {
    outputData[i] = Math.abs(inputData[i]) < threshold ? 0 : inputData[i];
  }
  return outputData;
}

export function floatTo16BitPCM(input: Float32Array): Int16Array {
  const output = new Int16Array(input.length);
  for (let i = 0; i < input.length; i++) {
    const s = Math.max(-1, Math.min(1, input[i]));
    output[i] = s < 0 ? s * 32768 : s * 32767;
  }
  return output;
}

export function downsampleBuffer(
  buffer: Float32Array,
  inputSampleRate: number,
  outputSampleRate: number
): Float32Array {
  if (outputSampleRate === inputSampleRate) {
    return buffer;
  }
  const sampleRateRatio = inputSampleRate / outputSampleRate;
  const newLength = Math.round(buffer.length / sampleRateRatio);
  const result = new Float32Array(newLength);
  let offsetResult = 0;
  let offsetBuffer = 0;
  while (offsetResult < result.length) {
    const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
    let accum = 0,
      count = 0;
    for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i];
      count++;
    }
    result[offsetResult] = accum / count;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }
  return result;
}

export function linearToMuLawSample(sample: number): number {
  const MU = 255;
  const MAX = 32768;

  // Clamp the sample value
  sample = Math.max(-MAX, Math.min(MAX, sample));

  // Get the sign and magnitude of the sample
  const sign = sample < 0 ? 0x80 : 0x00;
  sample = Math.abs(sample);

  // Apply μ-Law compression
  const muLawSample = Math.log(1 + (MU * sample) / MAX) / Math.log(1 + MU);

  // Quantize the sample to 8 bits
  const quantized = ((1 - muLawSample) * 127) | 0;

  // Combine the sign bit and the quantized value
  return sign | quantized;
}

export function linearToMuLaw(buffer: Int16Array): Uint8Array {
  const result = new Uint8Array(buffer.length);
  for (let i = 0; i < buffer.length; i++) {
    result[i] = linearToMuLawSample(buffer[i]);
  }
  return result;
}

export function uint8ArrayToBase64(bytes: Uint8Array) {
  let binary = '';
  for (let i = 0; i < bytes.length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function base64ToUint8Array(base64: string): Uint8Array {
  try {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  } catch (error) {
    console.error('Error decoding base64:', error);
    return new Uint8Array(); // Return empty array on error
  }
}

export function playBufferedAudio(
  muLawBufferRef: { current: Uint8Array[] },
  accumulatedDurationRef: { current: number },
  audioContextRef: { current: AudioContext | null },
  audioPlaybackTimeRef: { current: number }
) {
  // Concatenate the Uint8Array chunks
  const totalLength = muLawBufferRef.current.reduce((sum, chunk) => sum + chunk.length, 0);
  const concatenatedMuLawData = new Uint8Array(totalLength);
  let offset = 0;
  for (const chunk of muLawBufferRef.current) {
    concatenatedMuLawData.set(chunk, offset);
    offset += chunk.length;
  }

  // Clear the buffer and reset accumulated duration
  muLawBufferRef.current = [];
  accumulatedDurationRef.current = 0;

  // Process the concatenated data
  playReceivedAudio(concatenatedMuLawData, audioContextRef, audioPlaybackTimeRef);
}

async function playReceivedAudio(
  muLawData: Uint8Array,
  audioContextRef: { current: AudioContext | null },
  audioPlaybackTimeRef: { current: number }
) {
  // Ensure AudioContext is initialized
  if (!audioContextRef.current) {
    audioContextRef.current = new AudioContext();
    audioPlaybackTimeRef.current = audioContextRef.current.currentTime;
  }
  const audioContext = audioContextRef.current;

  // Decode μ-Law data to PCM16
  const pcm16Data = muLawToLinear(muLawData);

  // Normalize PCM16 data to prevent audio clipping
  normalizePCM16Data(pcm16Data);

  // Resample audio to match AudioContext sample rate and convert to Float32
  const sourceSampleRate = OUTBOUND_PACKET_SAMPLE_RATE; // Original sample rate
  const targetSampleRate = audioContext.sampleRate; // Device sample rate

  // convert PCM16 to Float32
  const resampledFloat32Data = resamplePCM16ToFloat32(pcm16Data, sourceSampleRate, targetSampleRate);

  // Create an AudioBuffer and fill it with the resampled data
  const audioBuffer = audioContext.createBuffer(1, resampledFloat32Data.length, targetSampleRate);
  const channelData = audioBuffer.getChannelData(0);
  channelData.set(resampledFloat32Data);

  // Create a buffer source and schedule it for playback
  const source = audioContext.createBufferSource();
  source.buffer = audioBuffer;

  // Apply a high-pass filter to remove low-frequency noise (optional)
  // const filteredNode = applyHighPassFilter(audioContext, source);
  // filteredNode.connect(audioContext.destination);

  source.connect(audioContext.destination);

  // Apply a band-pass filter to focus on speech frequencies (optional)
  // applyBandpassFilter(audioContext, source);

  // Apply a compressor to reduce dynamic range and background noise (optional)
  // applyCompressor(audioContext, source);

  // Schedule playback to ensure seamless audio
  if (audioPlaybackTimeRef.current < audioContext.currentTime) {
    // If playback time is behind, catch up to avoid gaps
    audioPlaybackTimeRef.current = audioContext.currentTime;
  }

  // Schedule the audio chunk
  source.start(audioPlaybackTimeRef.current);

  // Update the playback time for the next chunk
  audioPlaybackTimeRef.current += audioBuffer.duration;
}

function normalizePCM16Data(pcm16Data: Int16Array) {
  // Check for audio clipping and normalize PCM16 data
  let maxAmplitude = 0;
  for (let i = 0; i < pcm16Data.length; i++) {
    const absValue = Math.abs(pcm16Data[i]);
    if (absValue > maxAmplitude) {
      maxAmplitude = absValue;
    }
  }
  const maxAllowedAmplitude = 32767; // Max value for Int16
  if (maxAmplitude > maxAllowedAmplitude) {
    const normalizationFactor = maxAllowedAmplitude / maxAmplitude;
    for (let i = 0; i < pcm16Data.length; i++) {
      pcm16Data[i] = Math.round(pcm16Data[i] * normalizationFactor);
    }
  }
}

function resamplePCM16ToFloat32(
  pcm16Data: Int16Array,
  sourceSampleRate: number,
  targetSampleRate: number
): Float32Array {
  const sampleRateRatio = targetSampleRate / sourceSampleRate;
  const newLength = Math.round(pcm16Data.length * sampleRateRatio);
  const resampledData = new Float32Array(newLength);

  for (let i = 0; i < newLength; i++) {
    const sourceIndex = i / sampleRateRatio;
    const index0 = Math.floor(sourceIndex);
    const index1 = Math.min(index0 + 1, pcm16Data.length - 1);
    const weight = sourceIndex - index0;
    const sample = pcm16Data[index0] * (1 - weight) + pcm16Data[index1] * weight;
    resampledData[i] = sample / 32768; // Normalize to [-1, 1] Float32 range
  }

  return resampledData;
}

/* UNUSED FUNCTIONS */

export function base64ToUint8Array2(base64: string): Uint8Array {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

export async function playReceivedAudio2(
  muLawData: Uint8Array,
  audioContextRef: { current: AudioContext | null },
  audioPlaybackTimeRef: { current: number }
) {
  // Ensure AudioContext is initialized
  if (!audioContextRef.current) {
    audioContextRef.current = new AudioContext();
    audioPlaybackTimeRef.current = audioContextRef.current.currentTime;
  }
  const audioContext = audioContextRef.current;

  // Decode μ-Law data to PCM16
  const pcm16Data = muLawToLinear(muLawData);

  // Normalize and convert to Float32Array
  const float32Data = new Float32Array(pcm16Data.length);
  for (let i = 0; i < pcm16Data.length; i++) {
    float32Data[i] = pcm16Data[i] / 32768; // Normalize to [-1, 1]
  }

  // Create an AudioBuffer with the source sample rate (8000 Hz)
  const audioBuffer = audioContext.createBuffer(1, float32Data.length, 8000);
  audioBuffer.copyToChannel(float32Data, 0);

  const source = audioContext.createBufferSource();
  source.buffer = audioBuffer;
  source.connect(audioContext.destination);
  // Schedule playback to ensure seamless audio
  if (audioPlaybackTimeRef.current < audioContext.currentTime) {
    // If playback time is behind, catch up to avoid gaps
    audioPlaybackTimeRef.current = audioContext.currentTime;
  }

  // Schedule the audio chunk
  source.start(audioPlaybackTimeRef.current);

  // Update the playback time for the next chunk
  audioPlaybackTimeRef.current += audioBuffer.duration;
}

export function applyBandpassFilter(audioContext: AudioContext, source: AudioBufferSourceNode) {
  const bandpassFilter = audioContext.createBiquadFilter();
  bandpassFilter.type = 'bandpass';
  bandpassFilter.frequency.value = 1700; // Center frequency in Hz
  bandpassFilter.Q.value = 1; // Quality factor, adjust as needed

  source.connect(bandpassFilter);
  bandpassFilter.connect(audioContext.destination);
}

export function applyCompressor(audioContext: AudioContext, source: AudioBufferSourceNode) {
  const compressor = audioContext.createDynamicsCompressor();
  compressor.threshold.setValueAtTime(-50, audioContext.currentTime); // Threshold in dB
  compressor.knee.setValueAtTime(40, audioContext.currentTime); // Knee in dB
  compressor.ratio.setValueAtTime(12, audioContext.currentTime); // Ratio
  compressor.attack.setValueAtTime(0, audioContext.currentTime); // Attack time in seconds
  compressor.release.setValueAtTime(0.25, audioContext.currentTime); // Release time in seconds

  source.connect(compressor);
  compressor.connect(audioContext.destination);
}

export function applyHighPassFilter(audioContext: AudioContext, inputNode: AudioNode): AudioNode {
  const biquadFilter = audioContext.createBiquadFilter();
  biquadFilter.type = 'highpass';
  biquadFilter.frequency.value = 300; // Cut-off frequency at 300 Hz
  inputNode.connect(biquadFilter);
  return biquadFilter;
}

export function muLawToLinear2(muLawData: Uint8Array): Int16Array {
  const result = new Int16Array(muLawData.length);
  for (let i = 0; i < muLawData.length; i++) {
    result[i] = muLawToLinearSample(muLawData[i]);
  }
  return result;
}

export function muLawToLinearSample(muLawByte: number): number {
  const MU = 255;
  const MAX = 32768;

  // Extract the sign and quantized value
  const sign = muLawByte & 0x80 ? -1 : 1;
  const quantized = muLawByte & 0x7f;

  // Inverse quantize the sample
  const muLawSample = 1 - quantized / 127;

  // Apply μ-Law expansion
  const sample = sign * (((Math.pow(1 + MU, muLawSample) - 1) * MAX) / MU);

  return sample;
}

export function playAudioData(muLawData: Uint8Array, audioContextRef: { current: AudioContext | null }) {
  const pcm16Data = muLawToLinear(muLawData);

  const audioContext = audioContextRef.current || new AudioContext({ sampleRate: 8000 });
  audioContextRef.current = audioContext;

  const audioBuffer = audioContext.createBuffer(1, pcm16Data.length, 8000);
  const channelData = audioBuffer.getChannelData(0);

  for (let i = 0; i < pcm16Data.length; i++) {
    channelData[i] = pcm16Data[i] / 32768; // Convert Int16 to Float32
  }

  const source = audioContext.createBufferSource();
  source.buffer = audioBuffer;
  source.connect(audioContext.destination);
  source.start();
}

export function playOriginalAudio(inputData: Float32Array, audioContextRef: { current: AudioContext }) {
  const audioContext = audioContextRef.current;

  const audioBuffer = audioContext.createBuffer(1, inputData.length, audioContextRef.current.sampleRate);
  const channelData = audioBuffer.getChannelData(0);

  for (let i = 0; i < inputData.length; i++) {
    channelData[i] = inputData[i];
  }

  const source = audioContext.createBufferSource();
  source.buffer = audioBuffer;
  source.connect(audioContext.destination);
  source.start();
}

export function playDownsampledAudio(downsampledData: Float32Array, audioContextRef: { current: AudioContext | null }) {
  const audioContext = audioContextRef.current || new AudioContext({ sampleRate: 8000 });
  audioContextRef.current = audioContext;

  const audioBuffer = audioContext.createBuffer(1, downsampledData.length, 8000);
  const channelData = audioBuffer.getChannelData(0);

  for (let i = 0; i < downsampledData.length; i++) {
    channelData[i] = downsampledData[i];
  }

  const source = audioContext.createBufferSource();
  source.buffer = audioBuffer;
  source.connect(audioContext.destination);
  source.start();
}

function playPCM16Audio(pcm16Data: Int16Array, audioContextRef: { current: AudioContext | null }) {
  const audioContext = audioContextRef.current || new AudioContext({ sampleRate: 8000 });
  audioContextRef.current = audioContext;

  const audioBuffer = audioContext.createBuffer(1, pcm16Data.length, 8000);
  const channelData = audioBuffer.getChannelData(0);

  for (let i = 0; i < pcm16Data.length; i++) {
    channelData[i] = pcm16Data[i] / 32768; // Convert Int16 to Float32
  }

  const source = audioContext.createBufferSource();
  source.buffer = audioBuffer;
  source.connect(audioContext.destination);
  source.start();
}

export function playMuLawAudio(muLawData: Uint8Array, audioContextRef: { current: AudioContext | null }) {
  const pcm16Data = muLawToLinear(muLawData);
  playPCM16Audio(pcm16Data, audioContextRef);
}

export function playDecodedAudio(pcm16Data: Int16Array, audioContextRef: { current: AudioContext | null }) {
  const audioContext = audioContextRef.current || new AudioContext({ sampleRate: 8000 });
  audioContextRef.current = audioContext;

  const audioBuffer = audioContext.createBuffer(1, pcm16Data.length, 8000);
  const channelData = audioBuffer.getChannelData(0);

  for (let i = 0; i < pcm16Data.length; i++) {
    channelData[i] = pcm16Data[i] / 32768; // Convert Int16 to Float32
  }

  const source = audioContext.createBufferSource();
  source.buffer = audioBuffer;
  source.connect(audioContext.destination);
  source.start();
}
