import { useCallback } from 'react';
import { useSubmitLink } from '../../../hooks';
import { ComponentSize } from '../../../types';
import { TextInput } from '../../shared';
import UploadModalSubmitSection from './UploadModalSubmitSection';
import UploadModalSubmitSuccess from './UploadModalSubmitSuccess';

interface UploadModalSubmitLinkProps {
  error: string;
  isLinkSubmitted: boolean;
  linkInput: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setIsLinkSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setLinkInput: React.Dispatch<React.SetStateAction<string>>;
}

const UploadModalSubmitLink = ({
  error,
  isLinkSubmitted,
  linkInput,
  setError,
  setIsLinkSubmitted,
  setLinkInput,
}: UploadModalSubmitLinkProps) => {
  const { submitLink, isLoading: isSubmittingLink } = useSubmitLink();

  // Handler function to manage link change
  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkInput(e.target.value);
    setError('');
  };

  // Handler function to manage link submission
  const runSubmitLink = useCallback(async () => {
    const result = await submitLink(linkInput);
    if (result?.error) {
      setError(result.error);
    } else {
      setLinkInput('');
      setIsLinkSubmitted(true);
    }
  }, [linkInput, submitLink, setIsLinkSubmitted, setLinkInput]);

  if (isLinkSubmitted) return <UploadModalSubmitSuccess handleSubmitAnother={() => setIsLinkSubmitted(false)} />;

  return (
    <UploadModalSubmitSection
      title="Submit a link"
      onSubmit={runSubmitLink}
      isSubmitDisabled={!linkInput}
      isLoading={isSubmittingLink}
    >
      <TextInput
        placeholder="https://"
        value={linkInput}
        onChange={handleLinkChange}
        error={error}
        size={ComponentSize.MEDIUM}
      />
    </UploadModalSubmitSection>
  );
};

export default UploadModalSubmitLink;
