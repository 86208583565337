import { useCallback } from 'react';
import { useSubmitLink } from '../../../hooks';
import { TextInput } from '../../shared';
import CreateProspectUploadItem from './CreateProspectSubmitItem';

interface CreateProspectSubmitLinkProps {
  error: string;
  linkInput: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setLinkInput: React.Dispatch<React.SetStateAction<string>>;
  setIsLinkSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateProspectSubmitLink = ({
  error,
  linkInput,
  setError,
  setLinkInput,
  setIsLinkSubmitted,
}: CreateProspectSubmitLinkProps) => {
  const { submitLink, isLoading: isSubmittingLink } = useSubmitLink();

  const runSubmit = useCallback(async () => {
    if (!linkInput) return;

    const result = await submitLink(linkInput);

    if (result?.error) {
      setError(result.error);
    } else {
      setLinkInput('');
      setIsLinkSubmitted(true);
    }
  }, [linkInput, submitLink, setError, setIsLinkSubmitted, setLinkInput, setIsLinkSubmitted]);

  return (
    <CreateProspectUploadItem disabled={!linkInput} isLoading={isSubmittingLink} onSubmit={runSubmit}>
      <TextInput
        placeholder="https://"
        value={linkInput}
        onChange={(e) => setLinkInput(e.target.value)}
        error={error}
      />
    </CreateProspectUploadItem>
  );
};

export default CreateProspectSubmitLink;
