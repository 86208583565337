import dayjs from 'dayjs';
import { forwardRef } from 'react';
import { ComponentSize, DateFormat, Scorecard, TextColor } from '../../../../../types';
import {
  Avatar,
  ButtonColor,
  Icon,
  IconButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../../../shared';
import TableEmptyState from '../TableEmptyState';

interface ScorecardsTableProps {
  scorecards: Scorecard[];
  onCreateNewScorecard: () => void;
  onEditScorecard: (scorecard: Scorecard) => void;
  onDuplicateScorecard: (scorecard: Scorecard) => void;
}

const ScorecardsTable = forwardRef<HTMLDivElement, ScorecardsTableProps>(
  ({ scorecards, onCreateNewScorecard, onEditScorecard, onDuplicateScorecard }, ref) => {
    const archivedTextColor = TextColor.TERTIARY;

    if (!scorecards.length) {
      return (
        <TableEmptyState
          description="No scorecard templates"
          buttonText="Create new scorecard template"
          handleButtonClick={onCreateNewScorecard}
        />
      );
    }

    return (
      <div className="display-scrollbar-lg flex h-full max-h-full flex-col gap-4 overflow-auto" ref={ref}>
        {scorecards.map((scorecard) => (
          <div key={scorecard.id} className="flex items-center justify-between">
            <div className="flex flex-col gap-1">
              <Typography
                size={TypographySize.H5}
                color={scorecard.isArchived ? archivedTextColor : undefined}
                weight={TypographyWeight.SEMI_BOLD}
              >
                {scorecard.name}
              </Typography>
              <div className="flex items-center gap-2">
                <Avatar size={ComponentSize.X_SMALL} label={scorecard.creator} disabled={scorecard.isArchived} />
                <Typography size={TypographySize.CAPTION} color={scorecard.isArchived ? archivedTextColor : undefined}>
                  {scorecard.creator}
                </Typography>
                <Typography
                  size={TypographySize.CAPTION}
                  color={scorecard.isArchived ? archivedTextColor : TextColor.SECONDARY}
                >
                  {dayjs(scorecard.updatedOn).format(DateFormat.MONTH_DAY_TIME)}
                </Typography>
              </div>
            </div>

            <div className="flex gap-1">
              <IconButton
                icon={Icon.EDIT}
                color={ButtonColor.SECONDARY}
                size={ComponentSize.MEDIUM}
                onClick={() => onEditScorecard(scorecard)}
                disabled={scorecard.isArchived}
                tooltip="Edit"
              />
              <IconButton
                icon={Icon.DUPLICATE}
                color={ButtonColor.SECONDARY}
                size={ComponentSize.MEDIUM}
                onClick={() => onDuplicateScorecard({ ...scorecard, name: `Copy of ${scorecard.name}` })}
                tooltip="Duplicate"
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
);

ScorecardsTable.displayName = 'ScorecardsTable';
export default ScorecardsTable;
