import { useCallback } from 'react';
import { useUploadFile } from '../../../hooks';
import { FileInput } from '../../shared';
import CreateProspectUploadItem from './CreateProspectSubmitItem';

interface CreateProspectSubmitFileProps {
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setFileInput: React.Dispatch<React.SetStateAction<File | undefined>>;
  setIsFileSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  fileInput?: File;
}

const CreateProspectSubmitFile = ({
  error,
  fileInput,
  setError,
  setFileInput,
  setIsFileSubmitted,
}: CreateProspectSubmitFileProps) => {
  const { uploadFile, isLoading: isUploadingFile } = useUploadFile();

  const runSubmit = useCallback(async () => {
    if (!fileInput) return;

    const result = await uploadFile(fileInput);

    if (result?.error) {
      setError(error);
    } else {
      setFileInput(undefined);
      setIsFileSubmitted(true);
    }
  }, [fileInput, uploadFile, setError, setFileInput, setIsFileSubmitted]);

  return (
    <CreateProspectUploadItem disabled={!fileInput} isLoading={isUploadingFile} onSubmit={runSubmit}>
      <FileInput setSelectedFile={setFileInput} setError={setError} selectedFile={fileInput} error={error} />
    </CreateProspectUploadItem>
  );
};

export default CreateProspectSubmitFile;
