import clsx from 'clsx';
import React from 'react';
import { ComponentSize } from '../../../types';
import { convertToPx } from '../../../utils';
import { ButtonColor, ButtonVariant, IconButton } from '../Button';
import { Icon, IconComponent, Icons } from '../Icons';
import { Portal } from '../Portal';
import { Typography, TypographyComponent, TypographySize, TypographyWeight } from '../Typography';
import { DIALOG_TYPE_TO_WIDTH } from './Dialog.constants';
import { DialogProps, DialogType } from './Dialog.types';

const Dialog = ({
  children,
  isOpen,
  className,
  description: descriptionProp,
  footer,
  hideCloseButton,
  icon: iconProp,
  title,
  type = DialogType.DEFAULT,
  onClose,
  width,
}: DialogProps) => {
  const renderIcon = (icon: Icon | IconComponent) => {
    const iconSize = ComponentSize.LARGE;
    return typeof icon === 'string' ? (
      <Icons icon={icon} size={iconSize} />
    ) : (
      React.cloneElement(icon, { size: iconSize, ...icon.props })
    );
  };

  const renderDescription = (description: TypographyComponent | string) => {
    const textSize = TypographySize.H5;
    return typeof description === 'string' ? (
      <Typography size={textSize}>{description}</Typography>
    ) : (
      React.cloneElement(description, { size: textSize, ...description.props })
    );
  };

  return (
    <Portal>
      <dialog className="modal" open={isOpen} onClose={onClose}>
        {onClose && <div className="fixed inset-0 bg-gray-900 opacity-70" onClick={onClose} />}
        <div
          className={clsx(
            'modal-box flex max-w-none flex-col gap-4 rounded-lg bg-base-0 p-8 shadow-dialog',
            DIALOG_TYPE_TO_WIDTH[type],
            className
          )}
          style={{ width: convertToPx(width) }}
        >
          {!hideCloseButton && onClose && (
            <div className="absolute right-2 top-2">
              <IconButton
                onClick={onClose}
                icon={Icon.CLOSE}
                variant={ButtonVariant.GHOST}
                color={ButtonColor.SECONDARY}
              />
            </div>
          )}
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              {iconProp && renderIcon(iconProp)}
              <Typography weight={TypographyWeight.SEMI_BOLD} size={TypographySize.H2}>
                {title}
              </Typography>
            </div>
            {descriptionProp && renderDescription(descriptionProp)}
          </div>
          <div className="flex-grow overflow-auto">{children}</div>
          {footer && <div className="flex-grow overflow-auto">{footer}</div>}
        </div>
      </dialog>
    </Portal>
  );
};

export default Dialog;
