import { useCallback, useRef } from 'react';

const usePlayOutboundRing = () => {
  const isPlayingRef = useRef(false);
  const ringingSoundRef = useRef(
    new Audio(`${process.env.REACT_APP_PUBLIC_BUCKET_BASE_URL}/twilio-audio/outbound-call-ringing.wav`)
  );

  // Handles playing the ringing sound.
  const playRingingSound = useCallback(() => {
    if (isPlayingRef.current) return;
    isPlayingRef.current = true;
    ringingSoundRef.current.loop = true;
    ringingSoundRef.current.play();
  }, []);

  // Handles pausing and resetting the ringing sound.
  const pauseRingingSound = useCallback(() => {
    if (!isPlayingRef.current) return;
    isPlayingRef.current = false;
    ringingSoundRef.current.pause();
    ringingSoundRef.current.currentTime = 0;
  }, []);

  return { playRingingSound, pauseRingingSound };
};

export default usePlayOutboundRing;
