import { ComponentSize, TextColor } from '../../../types';
import { formatDuration } from '../../../utils';
import { ButtonVariant, Icon, Icons, TextButton, Typography, TypographySize } from '../../shared';

interface WebCallStatusProps {
  // Current time of the call in seconds.
  currentTime: number;
  // End time of the call in seconds.
  endTime: number;
  // Whether the user started the call.
  isCalling: boolean;
  // Whether the call is currently connected.
  isConnected: boolean;
  // Function to redial the call.
  redial: () => void;
}

const WebCallStatus = ({ currentTime, endTime, isCalling, isConnected, redial }: WebCallStatusProps) => {
  return (
    <div className="flex items-center gap-4">
      {/* Call ringing */}
      {isCalling && !isConnected && (
        <div className="flex w-full items-center gap-2">
          <Icons size={ComponentSize.LARGE} color={TextColor.SECONDARY} icon={Icon.PHONE_OUTGOING} />
          <Typography color={TextColor.SECONDARY} size={TypographySize.H5}>
            Ringing...
          </Typography>
        </div>
      )}
      {/* Call connected */}
      {isConnected && (
        <>
          <Typography size={TypographySize.H5}>{formatDuration(currentTime)}</Typography>
          <div className="flex w-full items-center gap-2">
            <Icons size={ComponentSize.LARGE} icon={Icon.PHONE_OUTGOING} color="text-primary" />
            <Typography size={TypographySize.H5} color="text-primary">
              Connected
            </Typography>
          </div>
        </>
      )}
      {/* Call ended */}
      {!isCalling && (
        <>
          <Typography size={TypographySize.H5}>{formatDuration(endTime)}</Typography>
          <TextButton
            text="Redial"
            variant={ButtonVariant.OUTLINE}
            fullWidth
            size={ComponentSize.X_SMALL}
            onClick={redial}
          />
        </>
      )}
      <Icons
        size={ComponentSize.LARGE}
        icon={Icon.CIRCLE_ALERT}
        tooltip="We recommend calling from a quiet place or using headphones"
        color={TextColor.SECONDARY}
      />
    </div>
  );
};

export default WebCallStatus;
