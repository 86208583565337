import { useEffect, useState } from 'react';
import { REACT_APP_ENV } from '../../../app.config';
import { useAppDispatch, useFeatureFlag, useToast } from '../../../hooks';
import { startWebCallWithResearcher } from '../../../redux/reducers';
import { LD_FeatureFlags, TextColor } from '../../../types';
import { formatDisplayedPhoneNumber } from '../../../utils';
import {
  Accordion,
  AlertType,
  ButtonVariant,
  Dialog,
  DialogType,
  Icon,
  TextButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../shared';
import CreateProspectSubmitFile from './CreateProspectSubmitFile';
import CreateProspectSubmitLink from './CreateProspectSubmitLink';
import CreateProspectSubmitSuccess from './CreateProspectSubmitSuccess';

const HOTLINE_PHONE_NUMBER = '+14159171029';

enum UploadType {
  FILE = 'FILE',
  LINK = 'LINK',
}

interface CreateProspectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateProspectModal = ({ isOpen, onClose }: CreateProspectModalProps) => {
  const [fileInput, setFileInput] = useState<File | undefined>(undefined);
  const [linkInput, setLinkInput] = useState('');

  const [fileInputError, setFileInputError] = useState('');
  const [linkInputError, setLinkInputError] = useState('');

  const [isFileSubmitted, setIsFileSubmitted] = useState(false);
  const [isLinkSubmitted, setIsLinkSubmitted] = useState(false);

  const { showToast } = useToast();

  const dispatch = useAppDispatch();
  const webCallFF = useFeatureFlag(LD_FeatureFlags.WEB_CALLING);

  const uploadAccordionItems = [
    {
      id: UploadType.FILE,
      title: <Typography size={TypographySize.H4}>Submit a file</Typography>,
      content: isFileSubmitted ? (
        <CreateProspectSubmitSuccess handleSubmitAnother={() => setIsFileSubmitted(false)} />
      ) : (
        <CreateProspectSubmitFile
          error={fileInputError}
          setError={setFileInputError}
          setFileInput={setFileInput}
          fileInput={fileInput}
          setIsFileSubmitted={setIsFileSubmitted}
        />
      ),
    },
    {
      id: UploadType.LINK,
      title: <Typography size={TypographySize.H4}>Submit a link</Typography>,
      content: isLinkSubmitted ? (
        <CreateProspectSubmitSuccess handleSubmitAnother={() => setIsLinkSubmitted(false)} />
      ) : (
        <CreateProspectSubmitLink
          error={linkInputError}
          setError={setLinkInputError}
          setLinkInput={setLinkInput}
          linkInput={linkInput}
          setIsLinkSubmitted={setIsLinkSubmitted}
        />
      ),
    },
  ];

  // Reset state on closing the modal.
  useEffect(() => {
    if (!isOpen) {
      setFileInput(undefined);
      setLinkInput('');

      setFileInputError('');
      setLinkInputError('');

      setIsFileSubmitted(false);
      setIsLinkSubmitted(false);
    }
  }, [isOpen]);

  return (
    <Dialog
      title="Create your Practice Prospect"
      isOpen={isOpen}
      onClose={onClose}
      type={DialogType.SETTINGS}
      description={
        <Typography color={TextColor.SECONDARY}>
          Call our hotline to get started. Amelia answers the phone 24/7!
        </Typography>
      }
    >
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <TextButton
            startIcon={Icon.CONCIERGE_BELL}
            text={`Call ${formatDisplayedPhoneNumber(HOTLINE_PHONE_NUMBER)}`}
            fullWidth
            onClick={() => {
              if (webCallFF && REACT_APP_ENV !== 'development') {
                onClose();
                dispatch(startWebCallWithResearcher());
              } else {
                window.location.href = `tel:${HOTLINE_PHONE_NUMBER}`;
              }
            }}
          />
          <TextButton
            startIcon={Icon.COPY}
            text="Copy phone number"
            onClick={() => {
              window.navigator.clipboard.writeText(HOTLINE_PHONE_NUMBER);
              showToast({
                message: 'Phone number copied to clipboard',
                type: AlertType.SUCCESS,
              });
            }}
            fullWidth
            variant={ButtonVariant.OUTLINE}
          />
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
              Create an AI twin of your prospect
            </Typography>
            <Typography color={TextColor.SECONDARY} size={TypographySize.H5}>
              Practice Prospects based on real calls. Upload a call transcript or audio file.
            </Typography>
          </div>
          {isOpen && <Accordion items={uploadAccordionItems} />}
        </div>
      </div>
    </Dialog>
  );
};

export default CreateProspectModal;
