import { show as openIntercom } from '@intercom/messenger-js-sdk';
import { useState } from 'react';
import { TextColor } from '../../../types';
import { ButtonColor, Icon, IconButton, TextButton } from '../../shared';

interface WebCallActionsProps {
  isMuted: boolean;
  endCall: () => void;
  mute: () => void;
  unmute: () => void;
}

const WebCallActions = ({ isMuted, endCall, mute, unmute }: WebCallActionsProps) => {
  const [isHoveringMute, setIsHoveringMute] = useState(false);

  const onMuteClick = () => {
    if (isMuted) unmute();
    else mute();
  };

  return (
    <div className="flex items-center justify-end gap-2">
      <IconButton
        icon={isMuted ? Icon.MIC_OFF : Icon.MIC_ON}
        color={ButtonColor.SECONDARY}
        iconColor={isHoveringMute ? TextColor.DESTRUCTIVE : undefined}
        onClick={onMuteClick}
        onHover={(hover) => setIsHoveringMute(hover)}
        active={isMuted}
        tooltip={isMuted ? 'Unmute' : 'Mute'}
      />
      <TextButton
        text="Report issue"
        startIcon={Icon.FLAG}
        color={ButtonColor.SECONDARY}
        onClick={openIntercom}
        fullWidth
      />
      <TextButton text="End call" color={ButtonColor.DESTRUCTIVE} onClick={endCall} fullWidth />
    </div>
  );
};

export default WebCallActions;
