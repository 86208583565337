import { useAuth0 } from '@auth0/auth0-react';
import { show as openIntercom } from '@intercom/messenger-js-sdk';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import fullyRampedLogo from '../../assets/logo-light.png';
import { AppRoutes } from '../../constants';
import { useAppDispatch, useAppSelector, useFeatureFlag } from '../../hooks';
import { SettingsModalTabs, openSettingsModal, openUploadModal } from '../../redux/reducers';
import { LD_FeatureFlags, Permissions, TextColor } from '../../types';
import { SettingsModal, UploadModal } from '../modals';
import {
  Avatar,
  ButtonColor,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  Icon,
  IconButton,
  Tabs,
  Typography,
  TypographyWeight,
} from '../shared';

const NavBar = () => {
  // Hooks
  const { logout } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const { isHiringModuleEnabled, isUploadModalEnabled } = useAppSelector((state) => state.auth.organization) || {};

  // Redux
  const dispatch = useAppDispatch();
  const { organization, user } = useAppSelector((state) => state.auth);

  // Check if the curr user is allowed to invite other users.
  const permissions = user?.permissions || [];
  const userCanInvite = permissions.includes(Permissions.INVITE_USER);

  // State
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const scorecardsFF = useFeatureFlag(LD_FeatureFlags.RELEASE_SCORECARDS);

  // Navigation tabs
  const tabs = [
    {
      id: AppRoutes.PRACTICE,
      icon: Icon.PHONE,
      title: 'Practice',
    },
    {
      id: AppRoutes.CALL_HISTORY,
      icon: Icon.HISTORY,
      title: 'Call history',
    },
  ];

  const activeTab = useMemo(
    () => tabs.find((tab) => location.pathname.includes(tab.id))?.id || AppRoutes.PRACTICE,
    [location.pathname]
  );

  return (
    <>
      <div className="navbar z-10 flex w-full justify-between border-b border-b-neutral-200 bg-base-0 px-4 py-2 shadow-sm">
        <div className="flex items-center gap-3">
          <img src={fullyRampedLogo} alt="FullyRamped Logo" className="h-8 w-8 rounded-md" />
          <Tabs ghost activeTab={activeTab} setActiveTab={(id) => navigate(id)} tabs={tabs} />
        </div>
        <div className="flex items-center gap-3">
          {isUploadModalEnabled && (
            <IconButton
              icon={Icon.UPLOAD}
              onClick={() => {
                dispatch(openUploadModal());
              }}
              tooltip="Upload file"
              color={ButtonColor.SECONDARY}
            />
          )}
          {userCanInvite && (
            <IconButton
              icon={Icon.USER_PLUS}
              onClick={() => {
                dispatch(openSettingsModal(SettingsModalTabs.ORGANIZATION));
              }}
              tooltip="Invite user"
              color={ButtonColor.SECONDARY}
            />
          )}
          <IconButton
            icon={Icon.CIRCLE_HELP}
            color={ButtonColor.SECONDARY}
            onClick={openIntercom}
            tooltip={window.Intercom ? 'Chat with support' : undefined}
            disabled={!window.Intercom}
          />
          <Dropdown open={isUserModalOpen} onOpenChange={setIsUserModalOpen}>
            <DropdownTrigger>
              <Avatar label={user?.name} imgSrc={user?.picture} />
            </DropdownTrigger>
            <DropdownContent>
              <DropdownItem
                label={
                  <Typography weight={TypographyWeight.SEMI_BOLD} color={TextColor.PRIMARY}>
                    {organization?.name}
                  </Typography>
                }
              />
              <DropdownItem
                label="Account setup"
                icon={Icon.SETTINGS}
                onClick={() => {
                  dispatch(openSettingsModal(SettingsModalTabs.PROFILE));
                  setIsUserModalOpen(false);
                }}
              />
              <DropdownItem
                label="Organization"
                icon={Icon.ORGANIZATION}
                onClick={() => {
                  dispatch(openSettingsModal(SettingsModalTabs.ORGANIZATION));
                  setIsUserModalOpen(false);
                }}
              />

              {scorecardsFF && (
                <DropdownItem
                  label="Scorecards"
                  icon={Icon.SCORECARDS}
                  onClick={() => {
                    dispatch(openSettingsModal(SettingsModalTabs.SCORECARDS));
                    setIsUserModalOpen(false);
                  }}
                />
              )}
              {permissions.includes(Permissions.VIEW_CANDIDATE) && (
                <DropdownItem
                  label="Hiring"
                  icon={isHiringModuleEnabled ? Icon.HANDSHAKE : Icon.LOCK}
                  disabled={!isHiringModuleEnabled}
                  onClick={() => {
                    dispatch(openSettingsModal(SettingsModalTabs.HIRING));
                    setIsUserModalOpen(false);
                  }}
                />
              )}
              <DropdownItem label="Logout" icon={Icon.LOG_OUT} onClick={logout} />
            </DropdownContent>
          </Dropdown>
        </div>
      </div>
      {/* Navbar Modals */}
      <SettingsModal />
      <UploadModal />
    </>
  );
};

export default NavBar;
