import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppOrganization, AppUser } from '../../types';

interface AuthState {
  // Flag indicating whether the user is authenticated.
  isAuthenticated: boolean;
  // Flag indicating whether the Redux authentication process is loading.
  isLoading: boolean;
  // Flag indicating whether the user has finished setting up their account.
  isOnboarded: boolean;
  // The time the user last logged in to the app.
  lastLoginTime: Date | null;
  // The current organization context, if any.
  organization: AppOrganization | null;
  // The current user context, if any.
  user: AppUser | null;
}

/** Defines the initial state for authentication related data. */
const initialAuthState: AuthState = {
  isAuthenticated: false,
  isLoading: false,
  isOnboarded: false,
  lastLoginTime: null,
  organization: null,
  user: null,
};

/** authSlice is a Redux slice that manages authentication, user, and organization state within the application. */
const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    // Resets the state to its initial state.
    logoutUser: () => initialAuthState,
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
      // If the user is authenticated, update the last login time.
      if (action.payload) {
        state.lastLoginTime = new Date();
      }
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUser: (state, action: PayloadAction<AppUser>) => {
      state.user = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      if (!state.user) return;
      state.user.name = action.payload;
    },
    setIsOnboarded: (state, action: PayloadAction<boolean>) => {
      state.isOnboarded = action.payload;
    },
    // Updates the last login time to the current date.
    updateLastLoginTime: (state) => {
      state.lastLoginTime = new Date();
    },
    setOrganization: (state, action: PayloadAction<AppOrganization>) => {
      state.organization = {
        ...state.organization,
        ...action.payload,
      };
    },
  },
});

export const {
  logoutUser,
  setIsAuthenticated,
  setIsLoading,
  setOrganization,
  setUser,
  setUserName,
  setIsOnboarded,
  updateLastLoginTime,
} = authSlice.actions;
export default authSlice.reducer;
