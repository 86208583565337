import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableAction, Icon } from '../../components';
import { AppRoutes } from '../../constants';
import { useAppDispatch, useShareCall } from '../../hooks';
import useDial from '../../hooks/useDial';
import { setCurrentCallDetails } from '../../redux/reducers';
import { Call, CallProcessingStatus } from '../../types';
import { conditionalArray } from '../../utils';

/**
 * Custom hook to generate actions for call rows in the data table.
 */
const useCallActions = (onClose: () => void, clickedCall?: Call): DataTableAction[] => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onDial = useDial(onClose);
  const { onShare } = useShareCall(clickedCall?.callSid, undefined, onClose);

  // Handles opening the media player with the specified call.
  const onListen = useCallback(() => {
    if (clickedCall) {
      dispatch(setCurrentCallDetails({ callSid: clickedCall.callSid, autoPlay: true }));
      onClose();
    }
  }, [clickedCall?.callSid]);

  // Handles opening the call details page.
  const onOpen = useCallback(() => {
    if (clickedCall) {
      // Navigate to the details page of the selected call.
      navigate(`${AppRoutes.CALL_HISTORY}/${clickedCall.callSid}`);
      onClose();
    }
  }, [clickedCall?.callSid]);

  return [
    // Show 'Processing...' if the call is still undergoing processing.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSING, {
      label: 'Processing...',
      icon: Icon.PLAY,
    }),
    // Show 'Processing Failed' if processing has failed.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSING_FAILED, {
      label: 'Processing Error',
      icon: Icon.PLAY,
    }),
    // Show 'Open' action if processing has succeeded.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSED, {
      label: 'Open',
      icon: Icon.OPEN,
      onClick: onOpen,
    }),
    // Show 'Listen' action only if the call's audioPath exists.
    ...conditionalArray(!!clickedCall?.audioPath, {
      label: 'Listen',
      icon: Icon.PLAY,
      onClick: onListen,
    }),
    {
      // 'Redial' action.
      label: 'Redial',
      icon: Icon.PHONE,
      onClick: () => onDial(clickedCall?.practiceProspect),
    },
    // Show 'Share' action if processing has succeeded.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSED, {
      label: 'Share',
      icon: Icon.SHARE,
      successMsg: 'Link to call copied to clipboard',
      onClick: onShare,
    }),
  ];
};

export default useCallActions;
