import clsx from 'clsx';
import { TextColor } from '../../../types';
import { Icons } from '../Icons';
import { Typography } from '../Typography';
import { TYPE_TO_ALERT_TYPE, TYPE_TO_ICON } from './Alert.constants';
import { AlertProps, AlertType } from './Alert.types';

const Alert = ({ message, type = AlertType.INFO }: AlertProps) => {
  const icon = TYPE_TO_ICON[type];
  return (
    <div className={clsx('alert border-none', TYPE_TO_ALERT_TYPE[type])}>
      <Icons icon={icon} color={TextColor.WHITE} />
      <Typography color={TextColor.WHITE}>{message}</Typography>
    </div>
  );
};

export default Alert;
