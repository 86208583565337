import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { GiveMicAccessModal, MediaPlayer, NavBar, Sidebar, WebCallModal } from '../../components';
import { useAppDispatch, useAppSelector, useIntercom } from '../../hooks';
import { setCurrentCallDetails, startWebCall } from '../../redux/reducers';
import { PracticeProspect } from '../../types';

function Layout() {
  const { callSid } = useParams();
  const isDetailsPage = !!callSid;

  const [isMicAccessModalOpen, setIsMicAccessModalOpen] = useState(false);
  const [retryWebCallProspect, setRetryWebCallProspect] = useState<PracticeProspect | undefined>(undefined);

  const dispatch = useAppDispatch();
  const currentCallSid = useAppSelector((state) => state.callHistory.currentCallDetails?.callSid);
  const currWebCall = useAppSelector((state) => state.webCall.currWebCall);

  useIntercom();

  // Handles closing the media player on navigating away from the Call details page.
  useEffect(() => {
    if (!isDetailsPage) {
      dispatch(setCurrentCallDetails(undefined));
    }
  }, [isDetailsPage]);

  // Handles closing the media player on opening the web call modal.
  useEffect(() => {
    if (currWebCall) {
      dispatch(setCurrentCallDetails(undefined));
      setRetryWebCallProspect(currWebCall.currProspect);
    }
  }, [currWebCall]);

  return (
    <div className="flex h-screen flex-col">
      <NavBar />
      <div className="flex flex-1 overflow-hidden">
        {!isDetailsPage && <Sidebar />}
        <div className="flex-1 overflow-auto bg-base-0 p-4">
          <Outlet />
        </div>
      </div>
      {currentCallSid && <MediaPlayer closable={!isDetailsPage} callSid={currentCallSid} />}
      {currWebCall && <WebCallModal currWebCall={currWebCall} setIsMicAccessModalOpen={setIsMicAccessModalOpen} />}
      <GiveMicAccessModal
        open={isMicAccessModalOpen}
        onClose={() => setIsMicAccessModalOpen(false)}
        onTryAgain={() => {
          if (retryWebCallProspect) {
            setIsMicAccessModalOpen(false);
            dispatch(startWebCall(retryWebCallProspect));
            setRetryWebCallProspect(undefined);
          }
        }}
      />
    </div>
  );
}

export default Layout;
