import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useFeatureFlag } from '../../../hooks';
import {
  SettingsModalTabs,
  closeSettingsModal,
  openSettingsModal,
  setSettingsActiveTab,
} from '../../../redux/reducers';
import { LD_FeatureFlags, Permissions } from '../../../types';
import { conditionalArray } from '../../../utils';
import { Dialog, DialogType, Icon, Tabs } from '../../shared';
import { HiringTab, OrganizationTab, ProfileTab, ScorecardsTab } from './tabs';

const SETTINGS_PARAM = 'settings';
const TAB_PARAM = 'tab';

const SettingsModal = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { permissions = [] } = useAppSelector((state) => state.auth.user) || {};
  const { isHiringModuleEnabled } = useAppSelector((state) => state.auth.organization) || {};
  const { isOpen, activeTab } = useAppSelector((state) => state.modal.settings);

  // Check if the curr user is allowed to view the hiring tab.
  const canViewHiring = permissions.includes(Permissions.VIEW_CANDIDATE);

  const isScorecardsEnabled = useFeatureFlag(LD_FeatureFlags.RELEASE_SCORECARDS);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // If the opensettings query param isn't present, do not proceed
    if (!queryParams.has(SETTINGS_PARAM)) return;

    const openSettings = queryParams.get(SETTINGS_PARAM);
    const tabParam = queryParams.get(TAB_PARAM);

    const resetQueryParams = () => {
      queryParams.delete(SETTINGS_PARAM);
      queryParams.delete(TAB_PARAM);
      navigate({ pathname: location.pathname, search: queryParams.toString() }, { replace: true });
    };

    // If the opensettings query param is not true, reset the query params
    if (openSettings !== 'true') {
      resetQueryParams();
      return;
    }

    // Check if the tab param is a valid tab
    const isValidTab = tabParam && Object.values(SettingsModalTabs).includes(tabParam as SettingsModalTabs);

    let targetTab: SettingsModalTabs | undefined;

    // If the tab param is a valid tab, consider setting it as the target tab
    if (isValidTab) {
      if (tabParam === SettingsModalTabs.HIRING) {
        // Only set hiring as the target if it's accessible and enabled
        targetTab = canViewHiring && isHiringModuleEnabled ? SettingsModalTabs.HIRING : SettingsModalTabs.PROFILE;
      } else {
        // For non-hiring tabs, set the target to the specified tab
        targetTab = tabParam as SettingsModalTabs;
      }
    }

    dispatch(openSettingsModal(targetTab));
    resetQueryParams();
  }, [dispatch, canViewHiring, isHiringModuleEnabled, location]);

  const tabs = [
    {
      id: SettingsModalTabs.PROFILE,
      title: 'Profile',
      content: <ProfileTab />,
    },
    {
      id: SettingsModalTabs.ORGANIZATION,
      title: 'Organization',
      content: <OrganizationTab />,
    },
    ...conditionalArray(isScorecardsEnabled, {
      content: <ScorecardsTab />,
      id: SettingsModalTabs.SCORECARDS,
      title: 'Scorecards',
    }),
    ...conditionalArray(canViewHiring, {
      content: <HiringTab />,
      disabled: !isHiringModuleEnabled,
      id: SettingsModalTabs.HIRING,
      title: 'Hiring',
      icon: !isHiringModuleEnabled ? Icon.LOCK : undefined,
    }),
  ];

  return (
    <Dialog
      title="Settings"
      isOpen={isOpen}
      onClose={() => dispatch(closeSettingsModal())}
      type={DialogType.SETTINGS}
      className="h-5/6 !pb-0"
    >
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={(newActiveTab) => dispatch(setSettingsActiveTab(newActiveTab as SettingsModalTabs))}
      />
    </Dialog>
  );
};

export default SettingsModal;
