import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { NUM_LOADING_SKELETONS } from '../../../../../constants';
import { useAppSelector } from '../../../../../hooks';
import { useGetInvitesQuery, useGetUsersQuery } from '../../../../../services';
import { AppUser, ComponentSize, Permissions } from '../../../../../types';
import { sortUsers } from '../../../../../utils';
import { Divider, Skeleton } from '../../../../shared';
import UserRow from './UserRow';

const UserTable = () => {
  const { permissions = [] } = useAppSelector((state) => state.auth.user) || {};
  const userCannotInvite = !permissions.includes(Permissions.INVITE_USER);

  const { data: users = [], isLoading } = useGetUsersQuery();
  const { data: invites = [] } = useGetInvitesQuery(undefined, { skip: userCannotInvite });

  // State to manage users with optimistic updates,
  // meaning immediate feedback on the UI without waiting for a response from the server,
  // providing a more responsive user experience.
  const [optimisticUsers, setOptimisticUsers] = useState<AppUser[]>([]);

  // Transform invitees data for consistent structure with users.
  const invitees = invites.map(({ id, email, role }) => ({
    id,
    email,
    name: email,
    role,
    isInvitee: true,
  }));

  // Combine and sort users and invitees.
  const sortedUsers = [...optimisticUsers, ...invitees].sort(sortUsers);

  // Update optimistic users state when users data changes.
  useEffect(() => {
    if (!isLoading) {
      setOptimisticUsers(users);
    }
  }, [users, isLoading]);

  return (
    <>
      {isLoading &&
        Array.from({ length: NUM_LOADING_SKELETONS }, (_, index) => (
          <div key={index}>
            <div className="flex justify-between py-4">
              <Skeleton size={ComponentSize.MEDIUM} />
            </div>
            {index < NUM_LOADING_SKELETONS - 1 && <Divider />}
          </div>
        ))}
      {!isLoading && (
        <div className="display-scrollbar-lg max-h-full overflow-auto">
          {sortedUsers.map((user, index) => {
            const isLastUser = index === sortedUsers.length - 1;
            return (
              <div key={user.id} className={clsx(isLastUser && 'pb-6')}>
                <UserRow rowUser={user} setUsers={setOptimisticUsers} />
                {!isLastUser && <Divider />}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default UserTable;
