import { ComponentSize, TextColor } from '../../../types';
import { BadgeColor } from './Badge.types';

// Maps badge size to DaisyUI classes.
export const BADGE_SIZE: Record<ComponentSize, string> = {
  [ComponentSize.X_SMALL]: 'badge-xs',
  [ComponentSize.SMALL]: 'badge-sm',
  [ComponentSize.MEDIUM]: 'badge-md',
  [ComponentSize.LARGE]: 'badge-lg',
};

export const BADGE_ICON_SIZE: Record<ComponentSize, ComponentSize> = {
  [ComponentSize.X_SMALL]: ComponentSize.X_SMALL,
  [ComponentSize.SMALL]: ComponentSize.X_SMALL,
  [ComponentSize.MEDIUM]: ComponentSize.SMALL,
  [ComponentSize.LARGE]: ComponentSize.SMALL,
};

export const OUTLINE_BADGE_ICON_COLOR: Record<BadgeColor, TextColor | string> = {
  [BadgeColor.DEFAULT]: TextColor.PRIMARY,
  [BadgeColor.NEUTRAL]: TextColor.PRIMARY,
  [BadgeColor.PRIMARY]: 'text-primary',
  [BadgeColor.SECONDARY]: 'text-secondary',
  [BadgeColor.ACCENT]: 'text-accent',
  [BadgeColor.ERROR]: 'text-error',
  [BadgeColor.SUCCESS]: 'text-success',
  [BadgeColor.GHOST]: 'text-ghost',
};

export const FILLED_BADGE_ICON_COLOR: Record<BadgeColor, TextColor | string> = {
  [BadgeColor.DEFAULT]: TextColor.PRIMARY,
  [BadgeColor.NEUTRAL]: TextColor.WHITE,
  [BadgeColor.PRIMARY]: TextColor.WHITE,
  [BadgeColor.SECONDARY]: TextColor.WHITE,
  [BadgeColor.ACCENT]: TextColor.WHITE,
  [BadgeColor.ERROR]: TextColor.DESTRUCTIVE,
  [BadgeColor.SUCCESS]: TextColor.SUCCESS,
  [BadgeColor.GHOST]: TextColor.PRIMARY,
};
