import clsx from 'clsx';
import { ComponentSize, TextColor } from '../../../types';
import { Icon, Icons } from '../Icons';
import { Typography, TypographySize } from '../Typography';

interface DataTableActionButtonProps {
  label: string;
  icon?: Icon;
  onClick?: () => void;
}

const DataTableActionButton = ({ label, icon, onClick }: DataTableActionButtonProps) => {
  const textColor = !onClick ? TextColor.SECONDARY : undefined;
  return (
    <div
      className={clsx(
        'flex cursor-pointer items-center gap-2 rounded-lg px-4 py-1 hover:bg-gray-300',
        !onClick && 'pointer-events-none'
      )}
      onClick={onClick}
    >
      {icon && <Icons icon={icon} color={textColor} size={ComponentSize.X_SMALL} />}
      <Typography size={TypographySize.CAPTION} color={textColor}>
        {label}
      </Typography>
    </div>
  );
};

export default DataTableActionButton;
