import { useAppSelector } from '../../../../../hooks';
import TableHeader from '../TableHeader';
import AddUser from './AddUser';
import UserTable from './UserTable';

const OrganizationTab = () => {
  const organization = useAppSelector((state) => state.auth.organization);

  if (!organization) return null;

  return (
    <div className="flex h-full w-full flex-col gap-4">
      <TableHeader title={organization.name}>
        <AddUser />
      </TableHeader>
      <UserTable />
    </div>
  );
};

export default OrganizationTab;
