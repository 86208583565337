import { useCallback } from 'react';
import { startWebCall } from '../redux/reducers';
import { LD_FeatureFlags, PracticeProspect } from '../types';
import useAppDispatch from './useAppDispatch';
import useFeatureFlag from './useFeatureFlag';

/**
 * Custom hook to handle dialing a phone number.
 */
const useDial = (onSuccess: () => void) => {
  const dispatch = useAppDispatch();

  const webCallFF = useFeatureFlag(LD_FeatureFlags.WEB_CALLING);

  // Starts a web call to the prospect at the given index.
  const onDial = useCallback(
    (prospect?: PracticeProspect) => {
      if (!prospect) return;

      // Initiate a phone call to the provided prospect.
      if (webCallFF) {
        dispatch(startWebCall(prospect));
      } else {
        window.location.href = `tel:${prospect.associatedPhoneNumber}`;
      }

      // Execute onSuccess callback.
      onSuccess();
    },
    [onSuccess]
  );

  return onDial;
};

export default useDial;
