import { BaseColor } from '../../../types';
import { DividerType } from './Divider.types';

export const DIVIDER_COLOR_TO_CLASSNAME = {
  [BaseColor.BASE_200]: 'divider-base-200',
  [BaseColor.BASE_300]: 'divider-base-300',
};

export const DIVIDER_TYPE_TO_CLASSNAME = {
  [DividerType.HORIZONTAL]: 'flex-row',
  [DividerType.VERTICAL]: 'flex-col',
};
